/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import { getToken } from '../../api/auth';
import calendarWithHourAndMinutesMask from '../../utils/masks/calendarWithHourAndMinutesMask';
import { SolicitationByIdFetch, SolicitationByIdUpdateFetch } from '../../api/services/SolicitationAPI';
import ActionsBySolicitation from '../../api/services/ActionAPI';

export default function SolicitationEdit({ history }) {
  const token = getToken();
  const [solicitation, setSolicitation] = useState(null);
  const [actions, setActions] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { solicitationId } = useParams();

  const navigate = useNavigate();

  async function getSolicitationById() {
    setIsLoad(true);
    try {
      const resultSolicitationById = await SolicitationByIdFetch(token, solicitationId);
      console.log('getSolicitationById | resultSolicitationById: ', resultSolicitationById);
      setIsLoad(false);
      setMessageWarning(resultSolicitationById.message);
      setIsSuccessMessage(resultSolicitationById.isSuccess);
      setSolicitation(resultSolicitationById.solicitation);
    } catch (error) {
      console.log('getSolicitationById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    async function getActionsBySolicitation() {
      setIsLoad(true);
      try {
        const resultActions = await ActionsBySolicitation(token, solicitationId);
        console.log('getActionsBySolicitation | resultActions: ', resultActions);
        setIsLoad(false);
        setMessageWarning(resultActions.message);
        setIsSuccessMessage(resultActions.isSuccess);
        if (resultActions.isSuccess) {
          setActions(resultActions.actions);
        }
      } catch (error) {
        console.log('getActionsBySolicitation | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getSolicitationById();
    getActionsBySolicitation();
  }, [history, token]);

  async function sendSolicitationByIdUpdate() {
    setIsLoad(true);
    try {
      const responseSolicitationByIdUpdate = await SolicitationByIdUpdateFetch(
        token,
        solicitationId,
        { is_producer_concluded: true },
      );
      console.log('sendSolicitationByIdUpdate | responseSolicitationByIdUpdate: ', responseSolicitationByIdUpdate);
      setIsLoad(false);
      setMessageWarning(responseSolicitationByIdUpdate.message);
      setIsSuccessMessage(responseSolicitationByIdUpdate.isSuccess);
      if (responseSolicitationByIdUpdate.isSuccess) {
        getSolicitationById();
      }
    } catch (error) {
      console.log('sendSolicitationByIdUpdate | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da Solicitação
          </p>

          <button
            className="form__button--outline"
            type="button"
            onClick={() => navigate('/solicitations')}
          >
            Voltar
          </button>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {solicitation !== null && solicitation.status === 'concluido' && solicitation.is_producer_concluded === false
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => sendSolicitationByIdUpdate()}
              >
                Confirmar conclusão
              </button>
            ) : null}

          {solicitation !== null && solicitation.status === 'concluido'
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => { window.location.href = 'https://forms.office.com/r/KcYL09yGrz'; }}
              >
                Avaliar atendimento
              </button>
            ) : null }

          {solicitation !== null
            ? (
              <>
                {solicitation.image !== null
                  ? (
                    <img
                      src={solicitation.image}
                      alt="LogoImage"
                      width="100%"
                      height="200"
                      style={{
                        objectFit: 'contain',
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                  ) : null}

                {solicitation.audio !== null
                  ? (
                    <audio
                      controls
                      src={solicitation.audio}
                      style={{
                        marginBottom: 10,
                        width: '88%',
                        borderRadius: 16,
                      }}
                    >
                      O seu navegador não suporta o elemento
                      {' '}
                      <code>audio</code>
                      .
                    </audio>
                  ) : null}

                <div className="detail__container">
                  <p className="detail__label">
                    Situação
                  </p>
                  <p className="detail__value">
                    {solicitation.status}
                  </p>

                  {solicitation.description !== null
                    ? (
                      <>
                        <p className="detail__label">
                          Descrição
                        </p>
                        <p className="detail__value">
                          {solicitation.description}
                        </p>
                      </>
                    ) : null }

                  <p className="detail__label">
                    Categoria
                  </p>
                  <p className="detail__value">
                    {solicitation.category.name}
                  </p>

                  {solicitation.technician !== null
                    ? (
                      <>
                        <p className="detail__label">
                          Técnico
                        </p>
                        <p className="detail__value">
                          {solicitation.technician.name}
                        </p>
                      </>
                    ) : null }

                  <p className="detail__label">
                    Criado Em
                  </p>
                  <p className="detail__value">
                    {calendarWithHourAndMinutesMask(solicitation.created)}
                  </p>
                </div>
              </>
            ) : null }

          <p className="form__description">
            Ações
          </p>

          {actions.map((action) => (
            <div className="detail__container">
              <p className="detail__label">
                Descrição
              </p>
              <p className="detail__value">
                {action.description}
              </p>

              <p className="detail__label">
                Realizado Em
              </p>
              <p className="detail__value">
                {calendarWithHourAndMinutesMask(action.created)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
