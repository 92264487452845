/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';

import './styles.css';

import weightMask from '../../../../utils/masks/weightMask';

export default function ProductionSummary({ productionParam, onAction }) {
  return (
    <div
      className="detail__container"
      onClick={() => onAction()}
      aria-hidden="true"
    >
      <p className="summary__value">PRODUÇÃO RECENTE</p>
      <div className="production--container-row">
        <p className="details--text details--text-title">
          PESO INICIAL
        </p>
        <p className="details--text details--text-title">
          PESO FINAL
        </p>
      </div>
      <div className="production--container-row">
        <p className="details--text details--text-description">
          {weightMask(productionParam.PESO_INICIAL)}
        </p>
        <p className="details--text details--text-description">
          {weightMask(productionParam.PESO_FINAL)}
        </p>
      </div>
      <div className="production--container-row">
        <p className="details--text details--text-title">
          VALOR UNITARIO
        </p>
        <p className="details--text details--text-title">
          DATA
        </p>
      </div>
      <div className="production--container-row">
        <p className="details--text details--text-description">
          {`R$ ${productionParam.VALOR_UNITARIO}`}
        </p>
        <p className="details--text details--text-description">
          {productionParam.DATA}
        </p>
      </div>
    </div>
  );
}
