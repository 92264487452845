/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';

import { RatingCreateFetch } from '../../api/services/StoreAPI';

export default function RatingCreate({ history }) {
  const token = getToken();

  const ratings = [
    { name: 0 },
    { name: 1 },
    { name: 2 },
    { name: 3 },
    { name: 4 },
    { name: 5 },
    { name: 6 },
    { name: 7 },
    { name: 8 },
    { name: 9 },
    { name: 10 },
  ];

  const [ratingSelected, setRatingSelected] = useState(ratings[0]);
  const [observation, setObservation] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();
  const { demandId } = useParams();

  async function sendRating(event) {
    event.preventDefault();
    try {
      setIsLoad(true);
      const resultProfileUpdate = await RatingCreateFetch(
        token,
        demandId,
        parseInt(ratingSelected),
        observation,
      );
      console.log('sendRating | resultProfileUpdate', resultProfileUpdate);
      setIsLoad(false);
      setIsSuccessMessage(resultProfileUpdate.isSuccess);
      setMessageWarning(resultProfileUpdate.message);
    } catch (error) {
      console.log('sendRating | error', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Registrar Avaliação
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {!isSuccessMessage
            ? (
              <>
                <div className="label__container">
                  <p className="form__label">
                    Nota (avalie seu pedido de 0 a 10)*
                  </p>
                </div>
                <select
                  className="form__select"
                  value={ratingSelected}
                  onChange={(event) => {
                    console.log('select | uf | event.target.value: ', event.target.value);
                    setRatingSelected(event.target.value);
                  }}
                >
                  {
                    ratings.map((rating) => (
                      <option value={rating.name} key={rating.name}>
                        {rating.name}
                      </option>
                    ))
                  }
                </select>

                <div className="label__container">
                  <p className="form__label">
                    Observação
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a observação"
                  value={observation}
                  onChange={(event) => setObservation(event.target.value)}
                />

                <button
                  className="form__button"
                  type="button"
                  onClick={(event) => sendRating(event)}
                >
                  Enviar
                </button>
              </>
            ) : null }

          {isSuccessMessage && messageWarning
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => navigate('/demand', { replace: true })}
              >
                Ir para os pedidos
              </button>
            ) : null }
        </div>
      </div>
    </>
  );
}
