/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarUSAMask from '../../utils/masks/calendarUSAMask';

import { FieldActivityByIdFetch } from '../../api/services/NotebookAPI';

export default function FieldActivityDetail({ history }) {
  const token = getToken();
  const [fieldActivity, setFieldActivity] = useState(null);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { fieldActivityId } = useParams();

  async function getFieldActivityById() {
    setIsLoad(true);
    try {
      const resultFieldActivityById = await FieldActivityByIdFetch(token, fieldActivityId);
      console.log('getFieldActivityById | resultFieldActivityById: ', resultFieldActivityById);
      setIsLoad(false);
      setMessageWarning(resultFieldActivityById.message);
      setIsSuccessMessage(resultFieldActivityById.isSuccess);
      setFieldActivity(resultFieldActivityById.fieldActivityDetail);
    } catch (error) {
      console.log('getFieldActivityById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getFieldActivityById();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da Atividade
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {fieldActivity !== null
            ? (
              <div className="detail__container">
                <p className="detail__label">
                  Atividade
                </p>
                <p className="detail__value">
                  {fieldActivity.activity}
                </p>

                <p className="detail__label">
                  Observação
                </p>
                <p className="detail__value">
                  {fieldActivity.observation}
                </p>

                <p className="detail__label">
                  Talhão
                </p>
                <p className="detail__value">
                  {fieldActivity.plot}
                </p>

                <p className="detail__label">
                  Criado Em
                </p>
                <p className="detail__value">
                  {calendarUSAMask(fieldActivity.created)}
                </p>
              </div>
            ) : null }
        </div>
      </div>
    </>
  );
}
