import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export default async function CategoriesFetch() {
  console.log('CategoriesFetch');
  const configRequest = {
    method: 'get',
    url: '/categories',
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
    );

    console.log('CategoriesFetch | response.data: ', response.data);

    return {
      isSuccess: true,
      message: '',
      categories: response.data,
    };
  } catch (error) {
    console.log('CategoriesFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );

    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}
