/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

export default function PlotList({ plotParam, onAction }) {
  return (
    <button
      className="details--container"
      type="button"
      onClick={() => onAction()}
    >
      <p className="details--text details--text-title">
        Nome
      </p>
      <p className="details--text details--text-description">
        {plotParam.name}
      </p>
      <p className="details--text details--text-title">
        Coordenada
      </p>
      <p className="details--text details--text-description">
        {plotParam.coordinate}
      </p>
      <p className="details--text details--text-title">
        Produção
      </p>
      <p className="details--text details--text-description">
        {plotParam.production}
      </p>
    </button>
  );
}
