/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

export default function PropertyDetailCard({ propertyDetailsParam }) {
  return (
    <div className="details--container">
      {propertyDetailsParam.registration !== ''
        ? (
          <>
            <p className="details--text details--text-title">
              Registro da Propriedade
            </p>
            <p className="details--text details--text-description">
              {propertyDetailsParam.registration}
            </p>
          </>
        ) : null}

      {propertyDetailsParam.name !== ''
        ? (
          <>
            <p className="details--text details--text-title">
              Nome da Propriedade
            </p>
            <p className="details--text details--text-description">
              {propertyDetailsParam.name}
            </p>
          </>
        ) : null}

      {propertyDetailsParam.dap !== ''
        ? (
          <>
            <p className="details--text details--text-title">
              DAP
            </p>
            <p className="details--text details--text-description">
              {propertyDetailsParam.dap}
            </p>
          </>
        ) : null}

      {propertyDetailsParam.car !== ''
        ? (
          <>
            <p className="details--text details--text-title">
              CAR
            </p>
            <p className="details--text details--text-description">
              {propertyDetailsParam.car}
            </p>
          </>
        ) : null}

      {propertyDetailsParam.access_description !== ''
        ? (
          <>
            <p className="details--text details--text-title">
              Descrição do Acesso a Propriedade
            </p>
            <p className="details--text details--text-description">
              {propertyDetailsParam.access_description}
            </p>
          </>
        ) : null}

      {propertyDetailsParam.coordinate_entry !== ''
        ? (
          <>
            <p className="details--text details--text-title">
              Coordenadas da Entrada
            </p>
            <p className="details--text details--text-description">
              {propertyDetailsParam.coordinate_entry}
            </p>
          </>
        ) : null}

      {propertyDetailsParam.coordinate_headquarters !== ''
        ? (
          <>
            <p className="details--text details--text-title">
              Coordenadas da Sede
            </p>
            <p className="details--text details--text-description">
              {propertyDetailsParam.coordinate_headquarters}
            </p>
          </>
        ) : null}

      {propertyDetailsParam.area_total !== ''
        ? (
          <>
            <p className="details--text details--text-title">
              Área Total
            </p>
            <p className="details--text details--text-description">
              {propertyDetailsParam.area_total}
            </p>
          </>
        ) : null}

      {propertyDetailsParam.area_agricultural !== ''
        ? (
          <>
            <p className="details--text details--text-title">
              Área de Agricultura
            </p>
            <p className="details--text details--text-description">
              {propertyDetailsParam.area_agricultural}
            </p>
          </>
        ) : null}

      {propertyDetailsParam.transporter !== ''
        ? (
          <>
            <p className="details--text details--text-title">
              Transportador
            </p>
            <p className="details--text details--text-description">
              {propertyDetailsParam.transporter}
            </p>
          </>
        ) : null}
    </div>
  );
}
