import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export default async function ProductionsByCropFetch(tokenAuthorization, cropPeriod) {
  console.log('ProductionsByCropFetch | tokenAuthorization, cropPeriod: ', tokenAuthorization, cropPeriod);
  const configRequest = {
    method: 'get',
    url: `/middleware/consult_production?harvest=${cropPeriod}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('ProductionsByCropFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      productions: response.data.productions,
    };
  } catch (error) {
    console.log('ProductionsByCropFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      productions: [],
    };
  }
}
