export default function itemsByAlphabeticalOrder(items, itemPropertyName) {
  function sortListAlphabetically(a, b) {
    const textA = (a[itemPropertyName]).toUpperCase();
    const textB = (b[itemPropertyName]).toUpperCase();
    if (textA < textB) {
      return -1;
    } if (textA > textB) {
      return 1;
    }
    return 0;
  }

  const itemsAlphabeticalOrder = items.sort(sortListAlphabetically);

  return itemsAlphabeticalOrder;
}
