/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import QualityDetailCard from './components/QualityDetailCard';
import QualityList from './components/QualityList';

import { getToken } from '../../api/auth';

import QualitiesFetch from '../../api/services/QualityAPI';

export default function Quality({ history }) {
  const token = getToken();
  const [qualities, setQualities] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [qualityDetail, setQualityDetail] = useState(null);
  const [isVisibleQualityDetailCard, setIsVisibleQualityDetailCard] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    async function getQualities() {
      setIsLoad(true);
      try {
        const resultQualities = await QualitiesFetch(token);
        console.log('getQualities | resultQualities: ', resultQualities);
        setIsLoad(false);
        setMessageWarning(resultQualities.message);
        setIsSuccessMessage(resultQualities.isSuccess);
        setQualities(resultQualities.qualities);
      } catch (error) {
        console.log('getQualities | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getQualities();
  }, [history, token]);

  function onVisibleQualityDetailCard(qualitySelected) {
    setIsVisibleQualityDetailCard(!isVisibleQualityDetailCard);
    setQualityDetail(qualitySelected);
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            {`${isVisibleQualityDetailCard ? 'Detalhes' : 'Histórico'} da Qualidade`}
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {isVisibleQualityDetailCard && qualityDetail !== null ? (
            <QualityDetailCard
              qualitySelectedParam={qualityDetail}
              onAction={() => onVisibleQualityDetailCard(null)}
            />
          )
            : null }

          {!isVisibleQualityDetailCard && qualityDetail === null ? (
            qualities.map((quality) => (
              <QualityList
                qualityParam={quality}
                onAction={() => onVisibleQualityDetailCard(quality)}
              />
            ))
          )
            : null }

          {!isVisibleQualityDetailCard && qualityDetail === null && qualities.length === 0 ? (
            <p className="form__message">
              Não há registro de laudos relacionada ao seu cadastro.
            </p>
          )
            : null }
        </div>
      </div>
    </>
  );
}
