/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarUSAMask from '../../utils/masks/calendarUSAMask';

import { ApplicationByIdFetch } from '../../api/services/NotebookAPI';

export default function ApplicationDetail({ history }) {
  const token = getToken();

  const [application, setApplication] = useState(null);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { applicationId } = useParams();

  async function getApplicationById() {
    setIsLoad(true);
    try {
      const resultApplicationById = await ApplicationByIdFetch(token, applicationId);
      console.log('getApplicationById | resultApplicationById: ', resultApplicationById);
      setIsLoad(false);
      setMessageWarning(resultApplicationById.message);
      setIsSuccessMessage(resultApplicationById.isSuccess);
      setApplication(resultApplicationById.applicationDetail);
    } catch (error) {
      console.log('getApplicationById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getApplicationById();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da Aplicação
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {application !== null
            ? (
              <div className="detail__container">
                <p className="detail__label">
                  Insumo Utilizado
                </p>
                <p className="detail__value">
                  {application.input}
                </p>

                <p className="detail__label">
                  Dosagem
                </p>
                <p className="detail__value">
                  {application.dosage}
                </p>

                <p className="detail__label">
                  Observação
                </p>
                <p className="detail__value">
                  {application.observation}
                </p>

                <p className="detail__label">
                  Talhão
                </p>
                <p className="detail__value">
                  {application.plot}
                </p>

                <p className="detail__label">
                  Criado Em
                </p>
                <p className="detail__value">
                  {calendarUSAMask(application.created)}
                </p>
              </div>
            ) : null }
        </div>
      </div>
    </>
  );
}
