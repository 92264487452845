/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import PropertyDetailCard from './components/PropertyDetailCard';
import PlotList from './components/PlotList';
import { getToken } from '../../api/auth';
import { PropertyByIdFetch, PlotsByPropertyIdFetch } from '../../api/services/PropertyAPI';

export default function PropertyDetails({ history }) {
  const token = getToken();
  const [messageWarning, setMessageWarning] = useState('');
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [plots, setPlots] = useState([]);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { propertyId } = useParams();

  useEffect(() => {
    async function getPlotsByPropertyId(protheusId) {
      setIsLoad(true);
      try {
        const resultPlots = await PlotsByPropertyIdFetch(token, protheusId);
        console.log('getPlotsByPropertyId | resultPlots: ', resultPlots);
        setIsLoad(false);
        setMessageWarning(resultPlots.message);
        setIsSuccessMessage(resultPlots.isSuccess);
        if (resultPlots.isSuccess) {
          setPlots(resultPlots.plots);
        }
      } catch (error) {
        console.log('getPlotsByPropertyId | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    async function getPropertyById() {
      setIsLoad(true);
      try {
        const resultPropertyById = await PropertyByIdFetch(token, propertyId);
        console.log('getPropertyById | resultPropertyById: ', resultPropertyById);
        setIsLoad(false);
        setMessageWarning(resultPropertyById.message);
        setIsSuccessMessage(resultPropertyById.isSuccess);
        if (resultPropertyById.isSuccess) {
          setPropertyDetail(resultPropertyById.property);
          getPlotsByPropertyId(resultPropertyById.property.protheus_id);
        }
      } catch (error) {
        console.log('getPropertyById | error: ', error);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getPropertyById();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da Propriedade
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {propertyDetail !== null
            ? (
              <PropertyDetailCard propertyDetailsParam={propertyDetail} />
            ) : null }

          <p className="form__description">
            Talhões
          </p>

          {plots.length === 0
            ? (
              <p className="form__message">Não há registro de talhão relacionada ao seu cadastro.</p>
            ) : null }

          {plots.map((plot) => (
            <PlotList plotParam={plot} />
          ))}
        </div>
      </div>
    </>
  );
}
