import Axios from '../Axios';
import createExceptionSentry from '../../utils/createExceptionSentry';

export async function FieldActivitiesFetch(tokenAuthorization) {
  console.log('FieldActivitiesFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/field_activities/field_activities_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('FieldActivitiesFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      fieldActivities: response.data.field_activities,
    };
  } catch (error) {
    console.log('FieldActivitiesFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function FieldActivityByIdFetch(tokenAuthorization, fieldActivityId) {
  console.log('FieldActivityByIdFetch | tokenAuthorization, fieldActivityId: ', tokenAuthorization, fieldActivityId);
  const configRequest = {
    method: 'get',
    url: `/field_activities/field_activity_by_id?field_activity_id=${fieldActivityId}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('FieldActivityByIdFetch | response.data.field_activity: ', response.data.field_activity);
    return {
      isSuccess: true,
      message: '',
      fieldActivityDetail: response.data.field_activity,
    };
  } catch (error) {
    console.log('FieldActivityByIdFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function FieldActivityCreateFetch(
  tokenAuthorization,
  activity,
  observation,
  plotName,
) {
  console.log(
    'FieldActivityCreateFetch | tokenAuthorization, activity, observation, plotName,: ',
    tokenAuthorization,
    activity,
    observation,
    plotName,
  );
  const configRequest = {
    method: 'post',
    url: '/field_activities/create_field_activity',
    body: { activity, observation, plot: plotName },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  console.log('FieldActivityCreateFetch | configRequest.body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('FieldActivityCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('FieldActivityCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401' || error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      createExceptionSentry(error, configRequest.method, configRequest.url, {
        ...configRequest.headers.headers,
        ...configRequest.body,
      });
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess: false,
      message,
    };
  }
}

export async function CropsFetch(tokenAuthorization) {
  console.log('CropsFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/crops/harvests_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('CropsFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      crops: response.data.harvests,
    };
  } catch (error) {
    console.log('CropsFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function CropByIdFetch(tokenAuthorization, cropId) {
  console.log('CropByIdFetch | tokenAuthorization, cropId: ', tokenAuthorization, cropId);
  const configRequest = {
    method: 'get',
    url: `/crops/${cropId}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('CropByIdFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      cropDetail: response.data,
    };
  } catch (error) {
    console.log('CropByIdFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function CropCreateFetch(
  tokenAuthorization,
  feedstock,
  quantity,
  quantityDiscarded,
  observation,
  plotName,
) {
  console.log(
    'CropCreateFetch | tokenAuthorization, activity, observation, plotName,: ',
    tokenAuthorization,
    feedstock,
    quantity,
    quantityDiscarded,
    observation,
    plotName,
  );
  const configRequest = {
    method: 'post',
    url: '/crops/create_harvest',
    body: {
      feedstock,
      quantity,
      quantity_discarded: quantityDiscarded,
      observation,
      plot: plotName,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  console.log('CropCreateFetch | configRequest.body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('CropCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('CropCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401' || error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      console.log('CropCreateFetch | error:', error.message);
      createExceptionSentry(error, configRequest.method, configRequest.url, {
        ...configRequest.headers.headers,
        ...configRequest.body,
      });
    }
    return {
      isSuccess: false,
      message,
    };
  }
}

export async function ApplicationsFetch(tokenAuthorization) {
  console.log('ApplicationsFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/applications/applications_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('ApplicationsFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      applications: response.data.applications,
    };
  } catch (error) {
    console.log('ApplicationsFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function ApplicationByIdFetch(tokenAuthorization, applicationId) {
  console.log('ApplicationByIdFetch | tokenAuthorization, applicationId: ', tokenAuthorization, applicationId);
  const configRequest = {
    method: 'get',
    url: `/applications/application_by_id?application_id=${applicationId}`,
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('ApplicationByIdFetch | response.data.aplication: ', response.data.application);
    return {
      isSuccess: true,
      message: '',
      applicationDetail: response.data.application,
    };
  } catch (error) {
    console.log('ApplicationByIdFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function ApplicationCreateFetch(
  tokenAuthorization,
  input,
  dosage,
  observation,
  plotName,
) {
  console.log(
    'ApplicationCreateFetch | tokenAuthorization, activity, observation, plotName,: ',
    tokenAuthorization,
    input,
    dosage,
    observation,
    plotName,
  );
  const configRequest = {
    method: 'post',
    url: '/applications/create_application',
    body: {
      input,
      dosage,
      observation,
      plot: plotName,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  console.log('ApplicationCreateFetch | configRequest.body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('ApplicationCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('FieldActivityCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401' || error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      console.log('ApplicationCreateFetch | error:', error.message);
      createExceptionSentry(error, configRequest.method, configRequest.url, {
        ...configRequest.headers.headers,
        ...configRequest.body,
      });
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess: false,
      message,
    };
  }
}

export async function CleaningsFetch(tokenAuthorization) {
  console.log('CleaningsFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/cleanings/cleanings_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('CleaningsFetch | response.data.cleanings: ', response.data.cleanings);
    return {
      isSuccess: true,
      message: '',
      cleanings: response.data.cleanings,
    };
  } catch (error) {
    console.log('CleaningsFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function CleaningByIdFetch(tokenAuthorization, cleaningId) {
  console.log('CleaningByIdFetch | tokenAuthorization, cleaningId: ', tokenAuthorization, cleaningId);
  const configRequest = {
    method: 'get',
    url: `/cleanings/cleaning_by_id?cleaning_id=${cleaningId}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('CleaningByIdFetch | response.data.cleaning: ', response.data.cleaning);
    return {
      isSuccess: true,
      message: '',
      cleaningDetail: response.data.cleaning,
    };
  } catch (error) {
    console.log('CleaningByIdFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function CleaningCreateFetch(
  tokenAuthorization,
  sanitizedItem,
  cleaningType,
  nameResponsible,
  nameChecker,
  observation,
) {
  console.log(
    'CleaningCreateFetch | tokenAuthorization, sanitizedItem, cleaningType, nameResponsible, nameChecker, observation: ',
    tokenAuthorization,
    sanitizedItem,
    cleaningType,
    nameResponsible,
    nameChecker,
    observation,
  );
  const configRequest = {
    method: 'post',
    url: '/cleanings/create_cleaning',
    body: {
      sanitized_item: sanitizedItem,
      type: cleaningType,
      name_responsible: nameResponsible,
      name_checker: nameChecker,
      observation,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  console.log('CleaningCreateFetch | configRequest.body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('CleaningCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('CleaningCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401' || error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      console.log('CleaningCreateFetch | error:', error.message);
      createExceptionSentry(error, configRequest.method, configRequest.url, {
        ...configRequest.headers.headers,
        ...configRequest.body,
      });
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess: false,
      message,
    };
  }
}

export async function FertilizationsFetch(tokenAuthorization) {
  console.log('FertilizationsFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/fertilizations/fertilizations_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('FertilizationsFetch | response.data.fertilizations: ', response.data.fertilizations);
    return {
      isSuccess: true,
      message: '',
      fertilizations: response.data.fertilizations,
    };
  } catch (error) {
    console.log('FertilizationsFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function FertilizingByIdFetch(tokenAuthorization, fertilizingId) {
  console.log('FertilizingByIdFetch | tokenAuthorization, fertilizingId: ', tokenAuthorization, fertilizingId);
  const configRequest = {
    method: 'get',
    url: `/fertilizations/fertilizing_by_id?fertilizing_id=${fertilizingId}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('FertilizingByIdFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      fertilizingDetail: response.data.fertilization,
    };
  } catch (error) {
    console.log('FertilizingByIdFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function FertilizingCreateFetch(
  tokenAuthorization,
  stackNumber,
  ingredients,
  dateMounting,
  dateFinishing,
  twistTemperature,
  twistTemperature2,
  twistTemperature3,
  twistTemperature4,
  twistTemperature5,
  observation,
) {
  console.log(
    'FertilizingCreateFetch | tokenAuthorization, sanitizedItem, cleaningType, nameResponsible, nameChecker, observation: ',
    tokenAuthorization,
    stackNumber,
    ingredients,
    dateMounting,
    dateFinishing,
    twistTemperature,
    twistTemperature2,
    twistTemperature3,
    twistTemperature4,
    twistTemperature5,
    observation,
  );
  const configRequest = {
    method: 'post',
    url: '/fertilizations/create_compost',
    body: {
      stack_number: stackNumber,
      ingredients,
      date_mounting: dateMounting,
      date_finishing: dateFinishing,
      twist_temperature_1: twistTemperature,
      twist_temperature_2: twistTemperature2,
      twist_temperature_3: twistTemperature3,
      twist_temperature_4: twistTemperature4,
      twist_temperature_5: twistTemperature5,
      observation,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  console.log('FertilizingCreateFetch | configRequest.body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('FertilizingCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('FertilizingCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401' || error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      console.log('FertilizingCreateFetch | error:', error.message);
      createExceptionSentry(error, configRequest.method, configRequest.url, {
        ...configRequest.headers.headers,
        ...configRequest.body,
      });
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess: false,
      message,
    };
  }
}

export async function PurchasesFetch(tokenAuthorization) {
  console.log('PurchasesFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/purchases/purchases_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('PurchasesFetch | response.data.purchases: ', response.data.purchases);
    return {
      isSuccess: true,
      message: '',
      purchases: response.data.purchases,
    };
  } catch (error) {
    console.log('PurchasesFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function PurchaseByIdFetch(tokenAuthorization, purchaseId) {
  console.log('PurchaseByIdFetch | tokenAuthorization, purchaseId: ', tokenAuthorization, purchaseId);
  const configRequest = {
    method: 'get',
    url: `/purchases/purchase_by_id?purchase_id=${purchaseId}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('PurchaseByIdFetch | response.data.purchase: ', response.data.purchase);
    return {
      isSuccess: true,
      message: '',
      purchaseDetail: response.data.purchase,
    };
  } catch (error) {
    console.log('PurchaseByIdFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function PurchaseCreateFetch(
  tokenAuthorization,
  invoice,
  product,
  provider,
) {
  console.log(
    'PurchaseCreateFetch | tokenAuthorization, invoice, product, provider: ',
    tokenAuthorization,
    invoice,
    product,
    provider,
  );
  const configRequest = {
    method: 'post',
    url: '/purchases/create_purchase',
    body: {
      invoice,
      product,
      provider,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  console.log('PurchaseCreateFetch | configRequest.body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('PurchaseCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('PurchaseCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401' || error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      console.log('PurchaseCreateFetch | error:', error.message);
      createExceptionSentry(error, configRequest.method, configRequest.url, {
        ...configRequest.headers.headers,
        ...configRequest.body,
      });
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess: false,
      message,
    };
  }
}

export async function OrganicChestnutsFetch(tokenAuthorization) {
  console.log('OrganicChestnutsFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/organic_chestnuts/organic_chestnuts_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('OrganicChestnutsFetch | response.data.organic_chestnuts: ', response.data.organic_chestnuts);
    return {
      isSuccess: true,
      message: '',
      organicChestnuts: response.data.organic_chestnuts,
    };
  } catch (error) {
    console.log('OrganicChestnutsFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function OrganicChestnutByIdFetch(
  tokenAuthorization,
  organicChestnutId,
) {
  console.log('OrganicChestnutByIdFetch | tokenAuthorization, organicChestnutId: ', tokenAuthorization, organicChestnutId);
  const configRequest = {
    method: 'get',
    url: `/organic_chestnuts/organic_chestnut_by_id?organic_chestnut_id=${organicChestnutId}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('OrganicChestnutByIdFetch | response.data.organic_chestnut: ', response.data.organic_chestnut);
    return {
      isSuccess: true,
      message: '',
      organicChestnutDetail: response.data.organic_chestnut,
    };
  } catch (error) {
    console.log('OrganicChestnutByIdFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function OrganicChestnutCreateFetch(
  tokenAuthorization,
  vehicleBrand,
  vehicleModel,
  vehicleLicensePlate,
  propertyId,
  organicProjectCode,
  city,
  weight,
  supervisorName,
  supervisorType,
) {
  console.log(
    'OrganicChestnutCreateFetch | tokenAuthorization, invoice, product, provider: ',
    tokenAuthorization,
    vehicleBrand,
    vehicleModel,
    vehicleLicensePlate,
    propertyId,
    organicProjectCode,
    city,
    weight,
    supervisorName,
    supervisorType,
  );
  const configRequest = {
    method: 'post',
    url: '/organic_chestnuts/create_organic_chestnut',
    body: {
      vehicle_brand: vehicleBrand,
      vehicle_model: vehicleModel,
      vehicle_license_plate: vehicleLicensePlate,
      property_id: propertyId,
      organic_project_code: organicProjectCode,
      city,
      weight,
      supervisor_name: supervisorName,
      supervisor_type: supervisorType,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  console.log('OrganicChestnutCreateFetch | configRequest.body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('OrganicChestnutCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('OrganicChestnutCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401' || error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      console.log('OrganicChestnutCreateFetch | error:', error.message);
      createExceptionSentry(error, configRequest.method, configRequest.url, {
        ...configRequest.headers.headers,
        ...configRequest.body,
      });
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess: false,
      message,
    };
  }
}

export async function ClaimsFetch(tokenAuthorization) {
  console.log('ClaimsFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/claims/claims_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('ClaimsFetch | response.data.claims: ', response.data.claims);
    return {
      isSuccess: true,
      message: '',
      claims: response.data.claims,
    };
  } catch (error) {
    console.log('ClaimsFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function ClaimByIdFetch(
  tokenAuthorization,
  claimId,
) {
  console.log('ClaimByIdFetch | tokenAuthorization, claimId: ', tokenAuthorization, claimId);
  const configRequest = {
    method: 'get',
    url: `/claims/claim_by_id?claim_id=${claimId}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('ClaimByIdFetch | response.data.claim: ', response.data.claim);
    return {
      isSuccess: true,
      message: '',
      claimDetail: response.data.claim,
    };
  } catch (error) {
    console.log('ClaimByIdFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function ClaimCreateFetch(
  tokenAuthorization,
  subject,
  product,
  lot,
  description,
  caseEvaluation,
  negotiation,
  dateCompletionActions,
  observation,
) {
  console.log(
    'ClaimCreateFetch: ',
    tokenAuthorization,
    subject,
    product,
    lot,
    description,
    caseEvaluation,
    negotiation,
    dateCompletionActions,
    observation,
  );
  const configRequest = {
    method: 'post',
    url: '/claims/create_claim',
    body: {
      subject,
      product,
      lot,
      description,
      case_evaluation: caseEvaluation,
      negotiation,
      date_completion_actions: dateCompletionActions,
      observation,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  console.log('ClaimCreateFetch | configRequest.body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('ClaimCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('ClaimCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401' || error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      console.log('ClaimCreateFetch | error:', error.message);
      createExceptionSentry(error, configRequest.method, configRequest.url, {
        ...configRequest.headers.headers,
        ...configRequest.body,
      });
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess: false,
      message,
    };
  }
}
