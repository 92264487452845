/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import NewsList from './components/NewsList';

import { NewsFetch } from '../../api/services/NewsAPI';

export default function News({ history }) {
  const [news, setNews] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  async function getNews() {
    setIsLoad(true);
    try {
      const resultNews = await NewsFetch();
      console.log('getNews | resultNews: ', resultNews);
      setIsLoad(false);
      setMessageWarning(resultNews.message);
      setIsSuccessMessage(resultNews.isSuccess);
      if (resultNews.isSuccess) {
        setNews(resultNews.news);
      }
    } catch (error) {
      console.log('getNews | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getNews();
  }, [history]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Noticias
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {news.length === 0
            ? (
              <p className="form__message">Não há registro de notícia.</p>
            ) : null }

          {news.map((newsLoop) => (
            <NewsList
              newsParam={newsLoop}
              onAction={() => navigate(`/news/${newsLoop.id}`, { replace: true })}
            />
          ))}
        </div>
      </div>
    </>
  );
}
