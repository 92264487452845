export default function removeLetter(text = '') {
  const textWithoutLetters = text.replace(
    /[abcdefghijklmnopqrstuvwxyz]/g,
    '',
  );
  const textWithoutLettersCapital = textWithoutLetters.replace(
    /[ABCDEFGHIJKLMNOPQRSTUVWXYZ]/g,
    '',
  );
  console.log(
    'removeSpecial | textWithoutLettersCapital: ',
    textWithoutLettersCapital,
  );

  return textWithoutLettersCapital;
}
