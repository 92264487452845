/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import Load from '../../components/Load';
import NavBar from '../../components/NavBar';

import { getToken } from '../../api/auth';
import removeLetter from '../../utils/masks/removeLetter';
import removeSpecial from '../../utils/masks/removeSpecial';

import { OrganicChestnutCreateFetch } from '../../api/services/NotebookAPI';
import { PropertiesFetch } from '../../api/services/PropertyAPI';

export default function OrganicChestnutCreate({ history }) {
  const token = getToken();
  const typesSupervisor = [
    { name: 'balanceiro' },
    { name: 'membro sci' },
    { name: 'controle qualidade' },
  ];

  const [supervisorTypeSelected, setSupervisorTypeSelected] = useState(typesSupervisor[0]);
  const [properties, setProperties] = useState([]);
  const [propertySelected, setPropertySelected] = useState({ name: '' });
  const [vehicleModel, setVehicleModel] = useState('');
  const [vehicleBrand, setVehicleBrand] = useState('');
  const [vehicleLicensePlate, setVehicleLicensePlate] = useState('');
  const [organicProjectCode, setOrganicProjectCode] = useState('');
  const [city, setCity] = useState('');
  const [weight, setWeight] = useState('');
  const [supervisorName, setSupervisorName] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getProperties() {
      setIsLoad(true);
      try {
        const resultProperties = await PropertiesFetch(token);
        console.log('getProperties | resultProperties: ', resultProperties);
        setIsLoad(false);
        setMessageWarning(resultProperties.message);
        if (resultProperties.isSuccess) {
          setProperties(resultProperties.properties);
          if ((resultProperties.properties).length >= 1) {
            setPropertySelected(resultProperties.properties[0]);
          }
        }
      } catch (error) {
        console.log('getProperties | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getProperties();
  }, [history, token]);

  async function sendOrganicChestnutCreate(event) {
    event.preventDefault();
    try {
      setIsLoad(true);
      if (!vehicleBrand) {
        setMessageWarning('preencha o campo marca do veículo transportador');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!vehicleModel) {
        setMessageWarning('preencha o campo modelo do veículo transportador');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!vehicleLicensePlate) {
        setMessageWarning('preencha o campo n° da placa do veículo transportador');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (propertySelected.name === '') {
        setMessageWarning('selecione uma propriedade');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!organicProjectCode) {
        setMessageWarning('preencha o campo código do projeto orgânico');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!city) {
        setMessageWarning('preencha o campo cidade');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!weight) {
        setMessageWarning('preencha o campo peso');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!supervisorName) {
        setMessageWarning('preencha o campo nome do fiscal');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else {
        const resultOrganicChestnutCreate = await OrganicChestnutCreateFetch(
          token,
          vehicleBrand,
          vehicleModel,
          vehicleLicensePlate,
          propertySelected.id,
          organicProjectCode,
          city,
          weight,
          supervisorName,
          supervisorTypeSelected,
        );
        console.log('sendOrganicChestnutCreate | resultOrganicChestnutCreate', resultOrganicChestnutCreate);
        setIsLoad(false);
        setIsSuccessMessage(resultOrganicChestnutCreate.isSuccess);
        setMessageWarning(resultOrganicChestnutCreate.message);
      }
    } catch (error) {
      console.log('sendReclamation | error', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Registrar Castanha Orgânica
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {!isSuccessMessage
            ? (
              <>
                <div className="label__container">
                  <p className="form__label">
                    Marca do Veículo Transportador *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a marca do veículo transportador"
                  value={vehicleBrand}
                  onChange={(event) => setVehicleBrand(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Modelo do Veículo Transportador *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a modelo do veículo transportador"
                  value={vehicleModel}
                  onChange={(event) => setVehicleModel(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    N° da Placa do Veículo Transportador *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o n° da placa do veículo transportador"
                  maxLength={8}
                  value={vehicleLicensePlate}
                  onChange={(event) => setVehicleLicensePlate(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Propriedade *
                  </p>
                </div>
                <select
                  className="form__select"
                  value={propertySelected.name}
                  onChange={(event) => {
                    console.log(event.target.value);
                    function isProperty(property) {
                      return property.name === event.target.value;
                    }
                    const propertyFound = properties.find(isProperty);
                    console.log('propertyFound: ', event.target.value);
                    setPropertySelected(propertyFound);
                  }}
                >
                  {
                    properties.map((property) => (
                      <option value={property.name} key={property.name}>{property.name}</option>
                    ))
                  }
                </select>

                <div className="label__container">
                  <p className="form__label">
                    Código do Projeto Orgânico *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o código do projeto orgânico"
                  value={organicProjectCode}
                  onChange={(event) => setOrganicProjectCode(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Cidade *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a cidade"
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Peso (KG) *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o peso"
                  value={weight}
                  onChange={(event) => {
                    const accountNumberWithoutLetter = removeLetter(event.target.value);
                    console.log('onChange | accountNumber | accountNumberWithoutLetter', accountNumberWithoutLetter);
                    const accountNumberWithoutSpecial = removeSpecial(accountNumberWithoutLetter);
                    console.log('onChange | accountNumber | accountNumberWithoutSpecial', accountNumberWithoutSpecial);
                    setWeight(accountNumberWithoutSpecial);
                  }}
                />

                <div className="label__container">
                  <p className="form__label">
                    Nome do Fiscal *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o nome do fiscal"
                  value={supervisorName}
                  onChange={(event) => setSupervisorName(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Cargo do Fiscal *
                  </p>
                </div>
                <select
                  className="form__select"
                  value={supervisorTypeSelected.name}
                  onChange={(event) => setSupervisorTypeSelected(event.target.value)}
                >
                  {
                    typesSupervisor.map((typeSupervisor) => (
                      <option
                        value={typeSupervisor.name}
                        key={typeSupervisor.name}
                      >
                        {typeSupervisor.name}
                      </option>
                    ))
                  }
                </select>

                <button
                  className="form__button"
                  type="button"
                  onClick={(event) => sendOrganicChestnutCreate(event)}
                >
                  Resgistrar
                </button>
              </>
            ) : null }

          {isSuccessMessage && messageWarning
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => navigate('/notebook/organic-chestnut', { replace: true })}
              >
                Ir para castanhas orgânicas
              </button>
            ) : null }
        </div>
      </div>
    </>
  );
}
