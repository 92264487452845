/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarUSAMask from '../../utils/masks/calendarUSAMask';

import { CleaningsFetch } from '../../api/services/NotebookAPI';

export default function Cleaning({ history }) {
  const token = getToken();
  const [cleanings, setCleanings] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getCleanings() {
      setIsLoad(true);
      try {
        const resultCleanings = await CleaningsFetch(token);
        console.log('getCleanings | resultCleanings: ', resultCleanings);
        setIsLoad(false);
        setMessageWarning(resultCleanings.message);
        setIsSuccessMessage(resultCleanings.isSuccess);
        if (resultCleanings.isSuccess) {
          setCleanings(resultCleanings.cleanings);
        }
      } catch (error) {
        console.log('getCleanings | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getCleanings();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Histórico de Limpezas
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <button
            className="form__button"
            type="button"
            // eslint-disable-next-line no-undef
            onClick={() => navigate('/notebook/cleaning/create', { replace: true })}
          >
            Registrar Limpeza
          </button>

          {cleanings.map((cleaning) => (
            <div
              className="detail__container"
              onClick={() => navigate(`/notebook/cleaning/${cleaning.id}`, { replace: true })}
              aria-hidden="true"
            >
              <p className="detail__value">
                {(cleaning.sanitized_item).toUpperCase()}
              </p>
              <p className="detail__value">
                {calendarUSAMask(cleaning.created)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
