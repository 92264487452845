export default function cnpjMask(value) {
  const cleanValue = value.replace(/\D/g, '').substring(0, 14);
  let finalValue = cleanValue.substring(0, 2);
  const part2 = cleanValue.substring(2, 5);
  if (part2) {
    finalValue += `.${part2}`;
    const part3 = cleanValue.substring(5, 8);
    if (part3) {
      finalValue += `.${part3}`;
      const part4 = cleanValue.substring(8, 12);
      if (part4) {
        finalValue += `/${part4}`;
        const part5 = cleanValue.substring(12, 14);
        if (part5) {
          finalValue += `-${part5}`;
        }
      }
    }
  }
  return finalValue;
}
