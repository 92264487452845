/* eslint-disable no-console */
import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export default async function QualitiesFetch(tokenAuthorization) {
  console.log('QualitiesFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/middleware/consult_quality',
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('QualitiesFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      qualities: response.data.laudos,
    };
  } catch (error) {
    console.log('QualitiesFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      qualities: [],
    };
  }
}
