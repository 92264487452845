/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-empty-pattern */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React from 'react';

export function BenefitsTable({ benefitsParam }) {
  console.log('BenefitsTable | benefitsParam: ', benefitsParam);

  return (
    <>
      {benefitsParam.map((benefit) => (
        <div className="container_benefits">
          <p className="benefits_description">{benefit}</p>
          <div className="benefits_border" />
        </div>
      ))}
    </>
  );
}
export default BenefitsTable;
