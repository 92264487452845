/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React from 'react';
import './styles.css';

export default function ModalNextLevel({
  onClose = () => {}, levelParam, medalIcon, requirementsParam, id = 'modal',
}) {
  const handleOutsideClick = (e) => {
    if (e.target.id == id) onClose();
  };

  return (
    <div id={id} className="modal" onClick={handleOutsideClick}>
      <div className="container">
        <div className="content">
          <div className="content__top">
            <p className="title__modal">
              Próximo nível
            </p>
            <img src={medalIcon} alt="newsImage" width="75%" height="160" style={{ objectFit: 'contain' }} />
            <p className="level__description">{levelParam}</p>
          </div>
          <div className="benefits__border" />
          <div className="content__bot">
            <p className="request__title">Requisitos</p>
            <p className="request__fix">Manter caderno de campo atualizado semanalmente</p>
          </div>
          <div className="benefits__border" />
          <p className="request__requirements">{requirementsParam}</p>
        </div>
      </div>
    </div>
  );
}
