/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarUSAMask from '../../utils/masks/calendarUSAMask';

import { FertilizingByIdFetch } from '../../api/services/NotebookAPI';

export default function FertilizingDetail({ history }) {
  const token = getToken();
  const [fertilizing, setFertilizing] = useState(null);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { fertilizingId } = useParams();

  async function getFertilizingById() {
    setIsLoad(true);
    try {
      const resultFertilizingById = await FertilizingByIdFetch(token, fertilizingId);
      console.log('getFertilizingById | resultFertilizingById: ', resultFertilizingById);
      setIsLoad(false);
      setMessageWarning(resultFertilizingById.message);
      setIsSuccessMessage(resultFertilizingById.isSuccess);
      setFertilizing(resultFertilizingById.fertilizingDetail);
    } catch (error) {
      console.log('getFertilizingById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getFertilizingById();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da Compostagem
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {fertilizing !== null
            ? (
              <div className="detail__container">
                <p className="detail__label">
                  N° da Pilha
                </p>
                <p className="detail__value">
                  {fertilizing.stack_number}
                </p>

                <p className="detail__label">
                  Ingredientes
                </p>
                <p className="detail__value">
                  {fertilizing.ingredients}
                </p>

                <p className="detail__label">
                  Data da Montagem
                </p>
                <p className="detail__value">
                  {fertilizing.date_mounting}
                </p>

                <p className="detail__label">
                  Revirada e Temperatura
                </p>
                <p className="detail__value">
                  {fertilizing.twist_temperature_1}
                </p>

                <p className="detail__label">
                  Revirada e Temperatura 2
                </p>
                <p className="detail__value">
                  {fertilizing.twist_temperature_2}
                </p>

                <p className="detail__label">
                  Revirada e Temperatura 3
                </p>
                <p className="detail__value">
                  {fertilizing.twist_temperature_3}
                </p>

                <p className="detail__label">
                  Revirada e Temperatura 4
                </p>
                <p className="detail__value">
                  {fertilizing.twist_temperature_4}
                </p>

                <p className="detail__label">
                  Revirada e Temperatura 5
                </p>
                <p className="detail__value">
                  {fertilizing.twist_temperature_5}
                </p>

                <p className="detail__label">
                  Observação
                </p>
                <p className="detail__value">
                  {fertilizing.observation}
                </p>

                <p className="detail__label">
                  Criado Em
                </p>
                <p className="detail__value">
                  {calendarUSAMask(fertilizing.created)}
                </p>
              </div>
            ) : null }
        </div>
      </div>
    </>
  );
}
