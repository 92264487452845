export default function removeSpecial(text = '') {
  const textWithoutSpecialCharacter = text.replace(
    /[!@#$%¨&*()-+{}]/g,
    '',
  );
  const textWithoutSpecialCharacter2 = textWithoutSpecialCharacter.replace(
    '[',
    '',
  );
  const textWithoutSpecialCharacter3 = textWithoutSpecialCharacter2.replace(
    ']',
    '',
  );
  const textWithoutSpecialCharacter4 = textWithoutSpecialCharacter3.replace(
    '_',
    '',
  );
  const textWithoutSpecialCharacter5 = textWithoutSpecialCharacter4.replace(
    '-',
    '',
  );
  const textWithoutSpecialCharacter6 = textWithoutSpecialCharacter5.replace(
    '<',
    '',
  );
  const textWithoutSpecialCharacter7 = textWithoutSpecialCharacter6.replace(
    '>',
    '',
  );
  const textWithoutSpecialCharacter8 = textWithoutSpecialCharacter7.replace(
    ':',
    '',
  );
  const textWithoutSpecialCharacter9 = textWithoutSpecialCharacter8.replace(
    '?',
    '',
  );
  const textWithoutSpecialCharacter10 = textWithoutSpecialCharacter9.replace(
    '´',
    '',
  );
  const textWithoutSpecialCharacter11 = textWithoutSpecialCharacter10.replace(
    '~',
    '',
  );
  const textWithoutSpecialCharacter12 = textWithoutSpecialCharacter11.replace(
    ';',
    '',
  );
  const textWithoutSpecialCharacter13 = textWithoutSpecialCharacter12.replace(
    '/',
    '',
  );
  const textWithoutSpecialCharacter14 = textWithoutSpecialCharacter13.replace(
    ',',
    '',
  );
  const textWithoutSpecialCharacter15 = textWithoutSpecialCharacter14.replace(
    '|',
    '',
  );
  const textWithoutSpecialCharacter16 = textWithoutSpecialCharacter15.replace(
    '.',
    '',
  );
  const textWithoutSpecialCharacter17 = textWithoutSpecialCharacter16.replace(
    'ç',
    '',
  );
  const textWithoutSpecialCharacter18 = textWithoutSpecialCharacter17.replace(
    'Ç',
    '',
  );
  const textWithoutSpecialCharacter19 = textWithoutSpecialCharacter18.replace(
    '^',
    '',
  );
  const textWithoutSpecialCharacter20 = textWithoutSpecialCharacter19.replace(
    '`',
    '',
  );
  // console.log(
  //   'removeSpecial | textWithoutSpecialCharacter: ',
  //   textWithoutSpecialCharacter,
  // );

  return textWithoutSpecialCharacter20;
}
