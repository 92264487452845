/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import calendarWithHourAndMinutesMask from '../../../../utils/masks/calendarWithHourAndMinutesMask';

export default function NewsList({ newsParam, onAction }) {
  return (
    <div
      className="news__container"
      onClick={() => onAction()}
      aria-hidden="true"
    >
      <img src={newsParam.image} alt="newsImage" width="100%" height="180" style={{ objectFit: 'cover' }} />
      <p className="titleNews__value">
        {(newsParam.title).toUpperCase()}
      </p>
      <p className="dateNews__value">
        {(calendarWithHourAndMinutesMask(newsParam.created))}
      </p>
      <p className="description__value">
        {(newsParam.description)}
      </p>
    </div>
  );
}
