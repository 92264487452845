/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoEye, IoEyeOff } from 'react-icons/io5';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import passwordIsInvalid from '../../utils/validation/passwordIsInvalid';

import { RedefinePasswordFetch } from '../../api/services/UserAPI';

export default function RedefinePassword({ history }) {
  const token = getToken();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isPasswordSecure, setIsPasswordSecure] = useState(true);
  const [isPasswordNewSecure, setIsPasswordNewSecure] = useState(true);
  const [isPasswordNewConfirmSecure, setIsPasswordNewConfirmSecure] = useState(true);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  async function sendRedefinePassword(event) {
    event.preventDefault();
    setIsLoad(true);
    if (!password) {
      setMessageWarning('Preencha o campo senha');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (password.length < 4) {
      setMessageWarning('Campo senha é inválido');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (!newPassword) {
      setMessageWarning('Preencha o campo nova senha');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (passwordIsInvalid(newPassword)) {
      setMessageWarning('Campo nova senha deve conter números, símbolos, letras maiúsculas e minúsculas');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (newPassword.length < 8 && newPassword.length > 15) {
      setMessageWarning('Campo nova senha deve conter de 8 à 15 caracteres');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (!confirmNewPassword) {
      setMessageWarning('Campo confirmar nova senha é inválido');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (confirmNewPassword.length < 4) {
      setMessageWarning('Campo confirmar nova senha é inválido');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (password === newPassword) {
      setMessageWarning('Campos senha atual e nova senha devem ser diferentes');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else if (newPassword !== confirmNewPassword) {
      setMessageWarning('Os campos nova senha e confirmar nova senha devem ser iguais');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else {
      try {
        const resultRedefinePassword = await RedefinePasswordFetch(
          token,
          password,
          newPassword,
        );
        console.log('sendRedefinePassword | resultRedefinePassword', resultRedefinePassword);
        setIsLoad(false);
        setIsSuccessMessage(resultRedefinePassword.isSuccess);
        setMessageWarning(resultRedefinePassword.message);
      } catch (error) {
        console.log('sendRedefinePassword | error', error);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsSuccessMessage(false);
        setIsLoad(false);
      }
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__header">
            Redefinir Senha
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <div className="label__container">
            <p className="form__label">
              Senha Atual *
            </p>
          </div>
          <div className="form__password">
            <input
              className="input-password"
              type={isPasswordSecure ? 'password' : 'text'}
              placeholder="Digite aqui sua senha atual"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            {isPasswordSecure
              ? (
                <IoEye
                  size={25}
                  color="#456A27"
                  onClick={() => setIsPasswordSecure(!isPasswordSecure)}
                />
              ) : (
                <IoEyeOff
                  size={25}
                  color="#456A27"
                  onClick={() => setIsPasswordSecure(!isPasswordSecure)}
                />
              )}
          </div>

          <div className="label__container">
            <p className="form__label">
              Nova Senha *
            </p>
          </div>
          <div className="form__password">
            <input
              className="input-password"
              type={isPasswordNewSecure ? 'password' : 'text'}
              placeholder="Digite aqui sua nova senha"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
            {isPasswordNewSecure
              ? (
                <IoEye
                  size={25}
                  color="#456A27"
                  onClick={() => setIsPasswordNewSecure(!isPasswordNewSecure)}
                />
              ) : (
                <IoEyeOff
                  size={25}
                  color="#456A27"
                  onClick={() => setIsPasswordNewSecure(!isPasswordNewSecure)}
                />
              )}
          </div>

          <div className="label__container">
            <p className="form__label">
              Confirmar Nova Senha *
            </p>
          </div>
          <div className="form__password">
            <input
              className="input-password"
              type={isPasswordNewConfirmSecure ? 'password' : 'text'}
              placeholder="Confirme aqui sua nova senha"
              value={confirmNewPassword}
              onChange={(event) => setConfirmNewPassword(event.target.value)}
            />
            {isPasswordNewConfirmSecure
              ? (
                <IoEye
                  size={25}
                  color="#456A27"
                  onClick={() => setIsPasswordNewConfirmSecure(!isPasswordNewConfirmSecure)}
                />
              ) : (
                <IoEyeOff
                  size={25}
                  color="#456A27"
                  onClick={() => setIsPasswordNewConfirmSecure(!isPasswordNewConfirmSecure)}
                />
              )}
          </div>

          <button
            className="form__button"
            type="button"
            onClick={(event) => sendRedefinePassword(event)}
          >
            Enviar
          </button>
          <button
            className="form__button--outline"
            type="button"
            onClick={() => navigate('/profile', { replace: true })}
          >
            Voltar
          </button>
        </div>
      </div>
    </>
  );
}
