/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import { IoEye, IoEyeOff } from 'react-icons/io5';

import './styles.css';

import { login, getToken } from '../../api/auth';

import Load from '../../components/Load';

import { SignInFetch } from '../../api/services/UserAPI';

const imageLogo = require('../../assets/image_logo.png');

export default function SignIn({ history }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordSecure, setIsPasswordSecure] = useState(true);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    function redirectAppScreen() {
      const tokenAuth = getToken();
      console.log('SignIn | tokenAuth: ', tokenAuth);
      if (tokenAuth !== null) {
        navigate('/home', { replace: true });
      }
    }

    redirectAppScreen();
  }, [history]);

  async function sendSignIn(event) {
    event.preventDefault();
    setIsLoad(true);
    if (!username || !password) {
      setMessageWarning('Preencha o nome de usuário e senha para continuar!');
      setIsLoad(false);
    } else {
      try {
        const resultSignIn = await SignInFetch(
          username,
          password,
        );
        console.log('sendSignIn | resultSignIn', resultSignIn);
        setIsLoad(false);
        setIsSuccessMessage(resultSignIn.isSuccess);
        setMessageWarning(resultSignIn.message);
        if (resultSignIn.isSuccess) {
          login(`Token ${resultSignIn.user.token}`);
          navigate('/home', { replace: true });
        }
      } catch (error) {
        console.log('sendSignIn | error', error);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
        setIsSuccessMessage(false);
      }
    }
  }

  return (
    <div className="container">
      <Load isShow={isLoad} />
      <form className="form" onSubmit={sendSignIn}>
        <img className="form__image" src={imageLogo} alt="Logo USIBRAS" />
        {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}
        <input
          className="form__input"
          type="text"
          placeholder="Nome do usuário"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <div className="form__password">
          <input
            className="input-password"
            type={isPasswordSecure ? 'password' : 'text'}
            placeholder="Senha"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          {isPasswordSecure
            ? (
              <IoEye
                size={25}
                color="#456A27"
                onClick={() => setIsPasswordSecure(!isPasswordSecure)}
              />
            ) : (
              <IoEyeOff
                size={25}
                color="#456A27"
                onClick={() => setIsPasswordSecure(!isPasswordSecure)}
              />
            )}
        </div>
        <div className="form__link--align">
          <Link className="redirect__link form__link--margin" to="/forgot-password">Esqueceu a senha?</Link>
        </div>
        <button className="form__button" type="submit">Entrar</button>
        <p className="redirect__text">
          Ainda não tem conta?
          <Link className="redirect__link" to="/sign-up"> Cadastre-se</Link>
        </p>
      </form>
    </div>
  );
}
