/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';

import calendarWithHourAndMinutesMask from '../../../../utils/masks/calendarWithHourAndMinutesMask';
import { addMoneyMask } from '../../../../utils/masks/manipulateMoneyMask';

export default function LoanSummary({ loanParam, onAction }) {
  function renderLoanStatus(loanStatus) {
    console.log('renderLoanStatus | loanStatus: ', loanStatus);
    if (loanStatus === 'aprovado') {
      console.log('renderLoanStatus | if | loanStatus === aprovado');
      return 'loan--text-approved';
    } if (loanStatus === 'recusado') {
      console.log('renderLoanStatus | if | loanStatus === recusado');
      return 'loan--text-recused';
    } if (loanStatus === 'em análise') {
      console.log('renderLoanStatus | if | loanStatus === em análise');
      return 'loan--text-analysis';
    }
    console.log('renderLoanStatus | else | details--text details--text-description');
    return 'details--text-description';
  }

  return (
    <div
      className="details--container"
      onClick={() => onAction()}
      aria-hidden="true"
    >
      <p className="summary__value">ANTECIPAÇÃO RECENTE</p>
      <p className="details--text details--text-title">
        {`N° ${loanParam.id}`}
      </p>
      <div className="production--container-row">
        <p className="details--text details--text-title">VALOR</p>
        <p className="details--text details--text-description">{addMoneyMask(loanParam.value)}</p>
      </div>
      <div className="production--container-row">
        <p className="details--text details--text-title">CRIADO EM</p>
        <p className="details--text details--text-description">{(calendarWithHourAndMinutesMask(loanParam.created)).toUpperCase()}</p>
      </div>
      <div className="production--container-row">
        <p className="details--text details--text-title">SITUAÇÃO</p>
        <p className={`details--text ${renderLoanStatus(loanParam.status)}`}>
          {loanParam.status}
        </p>
      </div>
    </div>
  );
}
