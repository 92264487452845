/* eslint-disable no-console */
import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export async function NewsFetch() {
  console.log('NewsFetch');
  const configRequest = {
    method: 'get',
    url: '/news',
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
    );
    console.log('NewsFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      news: response.data,
    };
  } catch (error) {
    console.log('NewsFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function NewsByIdFetch(newsId) {
  console.log('NewsByIdFetch | newsId: ', newsId);
  const configRequest = {
    method: 'get',
    url: `/news/news_by_id?news_id=${newsId}`,
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
    );

    console.log('NewsByIdFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      news: response.data.news,
    };
  } catch (error) {
    console.log('NewsByIdFetch | error: ', error);
    let message;
    if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
      );
    }

    return {
      isSuccess: false,
      message,
      news: null,
    };
  }
}
