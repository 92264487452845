/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable no-script-url */
/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import './styles.css';
import { logout } from '../../api/auth';

const { version } = require('../../../package.json');

function NavBar() {
  const navigate = useNavigate();

  async function exit(event) {
    event.preventDefault();

    try {
      logout();
      navigate('/', { replace: true });
    } catch (error) {
      console.log('exit | error', error);
    }
  }

  function hideNavLinks() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }

  return (
    <div className="topnav" id="myTopnav">
      <a href="/home" className="active">
        Agro FAN
        {` v${version}`}
      </a>
      <NavLink to="/solicitations">
        Solicitações
      </NavLink>
      <NavLink to="/news">
        Noticias
      </NavLink>
      <NavLink to="/store">
        Loja
      </NavLink>
      <NavLink to="/properties">
        Propriedades
      </NavLink>
      <NavLink to="/fanducaju">
        Fanducaju
      </NavLink>
      <NavLink to="/production">
        Produção
      </NavLink>
      <NavLink to="/bank-account">
        Banco
      </NavLink>
      <NavLink NavLink to="/financial">
        Financeiro
      </NavLink>
      <NavLink to="/profile">
        Perfil
      </NavLink>
      <NavLink to="/notebook">
        Caderno
      </NavLink>
      <NavLink to="/quality">
        Qualidade
      </NavLink>
      <a
        href="https://api.whatsapp.com/send?phone=5585992903907"
        target="_blank"
        rel="noreferrer"
        // onClick={() => { window.location.href = 'https://api.whatsapp.com/send?phone=5585992903907'; }}
      >
        SAC
      </a>
      <a
        href="#B"
        onClick={(event) => exit(event)}
      >
        Sair
      </a>
      <a
        href="javascript:void(0);"
        style={{ fontSize: 15 }}
        className="icon"
        onClick={() => hideNavLinks()}
      >
        &#9776;
      </a>
    </div>
  );
}

export default NavBar;
