/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import date from 'date-and-time';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import Load from '../../components/Load';
import NavBar from '../../components/NavBar';

import { getToken } from '../../api/auth';
import dateTimeMask from '../../utils/masks/dateTimeMask';

import { ClaimCreateFetch } from '../../api/services/NotebookAPI';

export default function ClaimCreate({ history }) {
  const token = getToken();

  const [subject, setSubject] = useState('');
  const [product, setProduct] = useState('');
  const [lot, setLot] = useState('');
  const [description, setDescription] = useState('');
  const [caseEvaluation, setCaseEvaluation] = useState('');
  const [negotiation, setNegotiation] = useState('');
  const [dateCompletionActions, setDateCompletionActions] = useState('');
  const [observation, setObservation] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  async function sendClaimCreate(event) {
    event.preventDefault();
    try {
      setIsLoad(true);
      const isValidDateCompletionActions = date.isValid(dateCompletionActions, 'DD/MM/YYYY');
      if (!subject) {
        setMessageWarning('preencha o campo assunto');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!product) {
        setMessageWarning('preencha o campo produto');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!lot) {
        setMessageWarning('preencha o campo lote');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!description) {
        setMessageWarning('preencha o campo descrição');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!caseEvaluation) {
        setMessageWarning('preencha o campo avaliação do caso');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!negotiation) {
        setMessageWarning('preencha o campo tratativa');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!isValidDateCompletionActions) {
        setMessageWarning('campo prazo de comprimento das ações é inválido');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else {
        const resultClaimCreate = await ClaimCreateFetch(
          token,
          subject,
          product,
          lot,
          description,
          caseEvaluation,
          negotiation,
          dateCompletionActions,
          observation,
        );
        console.log('sendClaimCreate | resultClaimCreate', resultClaimCreate);
        setIsLoad(false);
        setIsSuccessMessage(resultClaimCreate.isSuccess);
        setMessageWarning(resultClaimCreate.message);
      }
    } catch (error) {
      console.log('sendReclamation | error', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  function changeDateTime(textValue) {
    console.log('changePhone | textValue: ', textValue);
    const texDateTime = dateTimeMask(textValue);
    console.log('onChangeText | texDateTime: ', texDateTime);
    setDateCompletionActions(texDateTime);
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Registrar Reclamação
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {!isSuccessMessage
            ? (
              <>
                <div className="label__container">
                  <p className="form__label">
                    Assunto *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o assunto"
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Produto *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o produto"
                  value={product}
                  onChange={(event) => setProduct(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Lote *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o lote"
                  value={lot}
                  onChange={(event) => setLot(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Descrição *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o descrição"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Avaliação do Caso *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a descrição"
                  value={caseEvaluation}
                  onChange={(event) => setCaseEvaluation(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Tratativa *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a tratativa"
                  value={negotiation}
                  onChange={(event) => setNegotiation(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Prazo de Comprimento das Ações *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o prazo de comprimento das ações"
                  value={dateCompletionActions}
                  maxLength={10}
                  onChange={(event) => changeDateTime(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Observação
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a observação"
                  value={observation}
                  onChange={(event) => setObservation(event.target.value)}
                />

                <button
                  className="form__button"
                  type="button"
                  onClick={(event) => sendClaimCreate(event)}
                >
                  Resgistrar
                </button>
              </>
            ) : null }

          {isSuccessMessage && messageWarning
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => navigate('/notebook/claim', { replace: true })}
              >
                Ir para reclamações
              </button>
            ) : null }
        </div>
      </div>
    </>
  );
}
