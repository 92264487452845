export default function calendarUSAMask(date) {
  console.log('calendarUSAMask | date: ', date);
  const day = date.substring(8, 10);
  console.log('calendarUSAMask | day: ', day);
  const month = date.substring(5, 7);
  console.log('calendarUSAMask | month: ', month);
  const year = date.substring(0, 4);
  console.log('calendarUSAMask | year: ', year);

  return `${day}/${month}/${year}`;
}
