/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import PropertyList from './components/PropertyList';

import { getToken } from '../../api/auth';
import { PropertiesFetch } from '../../api/services/PropertyAPI';

export default function Property({ history }) {
  const token = getToken();
  const [properties, setProperties] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getProperties() {
      setIsLoad(true);
      try {
        const resultProperties = await PropertiesFetch(token);
        console.log('getProperties | resultProperties: ', resultProperties);
        setIsLoad(false);
        setMessageWarning(resultProperties.message);
        setIsSuccessMessage(resultProperties.isSuccess);
        if (resultProperties.isSuccess) {
          setProperties(resultProperties.properties);
        }
      } catch (error) {
        console.log('getProperties | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getProperties();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Propriedades
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {properties.length === 0
            ? (
              <p className="form__message">Não há registro de propriedade relacionada ao seu cadastro.</p>
            ) : null }

          {properties.map((property) => (
            <PropertyList
              propertyParam={property}
              onAction={() => navigate(`/properties/${property.id}`, { replace: true })}
            />
          ))}
        </div>
      </div>
    </>
  );
}
