/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import './styles.css';

export default function SlideBanner({ newsParam, onAction }) {
  return (
    <img
      id={`banner${newsParam.id}`}
      src={newsParam.image}
      alt="LogoImage"
      className="banner_image"
      hidden={newsParam.isHidden}
      onClick={() => onAction()}
      aria-hidden="true"
    />
  );
}
