/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import Load from '../../components/Load';
import NavBar from '../../components/NavBar';

import { getToken } from '../../api/auth';

import { CleaningCreateFetch } from '../../api/services/NotebookAPI';

export default function CleaningCreate({ history }) {
  const token = getToken();
  const typesCleaning = [
    { name: 'varrição' },
    { name: 'lavagem' },
  ];

  const [cleaningTypeSelected, setCleaningTypeSelected] = useState(typesCleaning[0].name);
  const [sanitizedItem, setSanitizedItem] = useState('');
  const [nameResponsible, setNameResponsible] = useState('');
  const [nameChecker, setNameChecker] = useState('');
  const [observation, setObservation] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  async function sendCleaningCreate(event) {
    event.preventDefault();
    try {
      setIsLoad(true);
      if (!sanitizedItem) {
        setMessageWarning('preencha o campo item / área higienizada');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!nameResponsible) {
        setMessageWarning('preencha o campo nome do responsável');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!nameChecker) {
        setMessageWarning('preencha o campo nome do verificador');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else {
        const resultCleaningCreate = await CleaningCreateFetch(
          token,
          sanitizedItem,
          cleaningTypeSelected,
          nameResponsible,
          nameChecker,
          observation,
        );
        console.log('sendCleaningCreate | resultCleaningCreate', resultCleaningCreate);
        setIsLoad(false);
        setIsSuccessMessage(resultCleaningCreate.isSuccess);
        setMessageWarning(resultCleaningCreate.message);
      }
    } catch (error) {
      console.log('sendCleaningCreate | error', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Registrar Limpeza
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {!isSuccessMessage
            ? (
              <>
                <div className="label__container">
                  <p className="form__label">
                    Item / Área Higienizada *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o item / área higienizada"
                  value={sanitizedItem}
                  onChange={(event) => setSanitizedItem(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Tipo de Limpeza *
                  </p>
                </div>
                <select
                  className="form__select"
                  value={cleaningTypeSelected}
                  onChange={(event) => setCleaningTypeSelected(event.target.value)}
                >
                  {
                    typesCleaning.map((typeCleaning) => (
                      <option
                        value={typeCleaning.name}
                        key={typeCleaning.name}
                      >
                        {typeCleaning.name}
                      </option>
                    ))
                  }
                </select>

                <div className="label__container">
                  <p className="form__label">
                    Nome do Responsável *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o nome do responsável"
                  value={nameResponsible}
                  onChange={(event) => setNameResponsible(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Nome do Verificador *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o nome do verificador"
                  value={nameChecker}
                  onChange={(event) => setNameChecker(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Observação
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a observação"
                  value={observation}
                  onChange={(event) => setObservation(event.target.value)}
                />

                <button
                  className="form__button"
                  type="button"
                  onClick={(event) => sendCleaningCreate(event)}
                >
                  Resgistrar
                </button>
              </>
            ) : null }

          {isSuccessMessage && messageWarning
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => navigate('/notebook/cleaning', { replace: true })}
              >
                Ir para Limpezas
              </button>
            ) : null }
        </div>
      </div>
    </>
  );
}
