/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from 'react-router-dom';

import { isAuthenticated } from './api/auth';

import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Profile from './pages/Profile';
import RedefinePassword from './pages/RedefinePassword';
import BankAccount from './pages/BankAccount';
import Property from './pages/Property';
import PropertyDetails from './pages/PropertyDetails';
import Fanducaju from './pages/Fanducaju';
import Production from './pages/Production';
import News from './pages/News';
import NewsDetail from './pages/NewsDetail';
import PlotDetail from './pages/PlotDetail';
import Solicitation from './pages/Solicitation';
import SolicitationEdit from './pages/SolicitationEdit';
import SolicitationCreate from './pages/SolicitationCreate';
import Financial from './pages/Financial';
import FinancialCreate from './pages/FinancialCreate';
import Notebook from './pages/Notebook';
import ClaimCreate from './pages/ClaimCreate';
import Claim from './pages/Claim';
import ClaimDetail from './pages/ClaimDetail';
import OrganicChestnutCreate from './pages/OrganicChestnutCreate';
import OrganicChestnut from './pages/OrganicChestnut';
import OrganicChestnutDetail from './pages/OrganicChestnutDetail';
import PurchaseCreate from './pages/PurchaseCreate';
import Purchase from './pages/Purchase';
import PurchaseDetail from './pages/PurchaseDetail';
import FertilizingCreate from './pages/FertilizingCreate';
import Fertilizing from './pages/Fertilizing';
import FertilizingDetail from './pages/FertilizingDetail';
import CleaningCreate from './pages/CleaningCreate';
import Cleaning from './pages/Cleaning';
import CleaningDetail from './pages/CleaningDetail';
import ApplicationCreate from './pages/ApplicationCreate';
import Application from './pages/Application';
import ApplicationDetail from './pages/ApplicationDetail';
import CropCreate from './pages/CropCreate';
import Crop from './pages/Crop';
import CropDetail from './pages/CropDetail';
import FieldActivityCreate from './pages/FieldActivityCreate';
import FieldActivity from './pages/FieldActivity';
import FieldActivityDetail from './pages/FieldActivityDetail';
import Store from './pages/Store';
import StoreDetail from './pages/StoreDetail';
import Demand from './pages/Demand';
import DemandDetail from './pages/DemandDetail';
import RatingCreate from './pages/RatingCreate';
import Quality from './pages/Quality';

function RequireAuth({ children }) {
  const location = useLocation();
  const isAuth = isAuthenticated();
  console.log('RequireAuth | isAuth: ', isAuth);

  if (!isAuth) {
    // console.log('RequireAuth | if | isAuth: ', isAuth);
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

function Navigator() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/home"
          element={(
            <RequireAuth>
              <Home />
            </RequireAuth>
          )}
        />
        <Route
          path="/profile"
          element={(
            <RequireAuth>
              <Profile />
            </RequireAuth>
          )}
        />
        <Route
          path="/redefine-password"
          element={(
            <RequireAuth>
              <RedefinePassword />
            </RequireAuth>
          )}
        />
        <Route
          path="/bank-account"
          element={(
            <RequireAuth>
              <BankAccount />
            </RequireAuth>
          )}
        />
        <Route
          path="/production"
          element={(
            <RequireAuth>
              <Production />
            </RequireAuth>
          )}
        />
        <Route
          path="/fanducaju"
          element={(
            <RequireAuth>
              <Fanducaju />
            </RequireAuth>
          )}
        />
        <Route
          path="/news"
          element={(
            <RequireAuth>
              <News />
            </RequireAuth>
          )}
        />
        <Route
          path="news/:newsId"
          element={(
            <RequireAuth>
              <NewsDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/financial"
          element={(
            <RequireAuth>
              <Financial />
            </RequireAuth>
          )}
        />
        <Route
          path="/financial/create"
          element={(
            <RequireAuth>
              <FinancialCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="/properties"
          element={(
            <RequireAuth>
              <Property />
            </RequireAuth>
          )}
        />
        <Route
          path="properties/:propertyId"
          element={(
            <RequireAuth>
              <PropertyDetails />
            </RequireAuth>
          )}
        />
        <Route
          path="plots/:plotId"
          element={(
            <RequireAuth>
              <PlotDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/solicitations"
          element={(
            <RequireAuth>
              <Solicitation />
            </RequireAuth>
          )}
        />
        <Route
          path="/solicitations/create"
          element={(
            <RequireAuth>
              <SolicitationCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="solicitations/:solicitationId"
          element={(
            <RequireAuth>
              <SolicitationEdit />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook"
          element={(
            <RequireAuth>
              <Notebook />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/claim/create"
          element={(
            <RequireAuth>
              <ClaimCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/claim"
          element={(
            <RequireAuth>
              <Claim />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/claim/:claimId"
          element={(
            <RequireAuth>
              <ClaimDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/organic-chestnut/create"
          element={(
            <RequireAuth>
              <OrganicChestnutCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/organic-chestnut"
          element={(
            <RequireAuth>
              <OrganicChestnut />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/organic-chestnut/:organicChestnutId"
          element={(
            <RequireAuth>
              <OrganicChestnutDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/purchase/create"
          element={(
            <RequireAuth>
              <PurchaseCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/purchase"
          element={(
            <RequireAuth>
              <Purchase />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/purchase/:purchaseId"
          element={(
            <RequireAuth>
              <PurchaseDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/fertilizing/create"
          element={(
            <RequireAuth>
              <FertilizingCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/fertilizing"
          element={(
            <RequireAuth>
              <Fertilizing />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/fertilizing/:fertilizingId"
          element={(
            <RequireAuth>
              <FertilizingDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/cleaning/create"
          element={(
            <RequireAuth>
              <CleaningCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/cleaning"
          element={(
            <RequireAuth>
              <Cleaning />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/cleaning/:cleaningId"
          element={(
            <RequireAuth>
              <CleaningDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/application/create"
          element={(
            <RequireAuth>
              <ApplicationCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/application"
          element={(
            <RequireAuth>
              <Application />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/application/:applicationId"
          element={(
            <RequireAuth>
              <ApplicationDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/crop/create"
          element={(
            <RequireAuth>
              <CropCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/crop"
          element={(
            <RequireAuth>
              <Crop />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/crop/:cropId"
          element={(
            <RequireAuth>
              <CropDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/field-activity/create"
          element={(
            <RequireAuth>
              <FieldActivityCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/field-activity"
          element={(
            <RequireAuth>
              <FieldActivity />
            </RequireAuth>
          )}
        />
        <Route
          path="/notebook/field-activity/:fieldActivityId"
          element={(
            <RequireAuth>
              <FieldActivityDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/store"
          element={(
            <RequireAuth>
              <Store />
            </RequireAuth>
          )}
        />
        <Route
          path="store/:itemId"
          element={(
            <RequireAuth>
              <StoreDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="/demand"
          element={(
            <RequireAuth>
              <Demand />
            </RequireAuth>
          )}
        />
        <Route
          path="demand/:demandId"
          element={(
            <RequireAuth>
              <DemandDetail />
            </RequireAuth>
          )}
        />
        <Route
          path="rating/:demandId"
          element={(
            <RequireAuth>
              <RatingCreate />
            </RequireAuth>
          )}
        />
        <Route
          path="/quality"
          element={(
            <RequireAuth>
              <Quality />
            </RequireAuth>
          )}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigator;
