/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

import Load from '../../components/Load';

import { ForgotPasswordFetch } from '../../api/services/UserAPI';

export default function ForgotPassword() {
  const [username, setUserName] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  async function sendForgotPassword() {
    setIsLoad(true);
    if (!username) {
      setMessageWarning('Preencha com nome de usuário para continuar');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else {
      try {
        const resultForgotPassword = await ForgotPasswordFetch(username);
        // console.log('sendForgotPassword | resultForgotPassword', resultForgotPassword);
        setIsLoad(false);
        setIsSuccessMessage(resultForgotPassword.isSuccess);
        setMessageWarning(resultForgotPassword.message);
      } catch (error) {
        console.log('sendForgotPassword | error', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }
  }

  return (
    <div className="container">
      <Load isShow={isLoad} />
      <div className="form">
        <p className="form__header">
          Esqueci minha senha
        </p>
        {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

        {!isSuccessMessage
          ? (
            <>
              <div className="label__container">
                <p className="form__label">
                  Nome de Usuário *
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui seu nome de usuário"
                value={username}
                onChange={(event) => setUserName(event.target.value)}
              />
              <button
                className="form__button"
                type="button"
                onClick={() => sendForgotPassword()}
              >
                Enviar
              </button>
              <p className="redirect__text">
                <Link className="redirect__link" to="/">Voltar ao acesso</Link>
              </p>
            </>
          )
          : (
            <p className="redirect__text">
              <Link className="redirect__link" to="/">Voltar ao acesso</Link>
            </p>
          )}
      </div>
    </div>
  );
}
