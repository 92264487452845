/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarUSAMask from '../../utils/masks/calendarUSAMask';

import { ClaimByIdFetch } from '../../api/services/NotebookAPI';

export default function ClaimDetail({ history }) {
  const token = getToken();
  const [claim, setClaim] = useState(null);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { claimId } = useParams();

  async function getClaimById() {
    setIsLoad(true);
    try {
      const resultClaimById = await ClaimByIdFetch(token, claimId);
      console.log('getClaimById | resultClaimById: ', resultClaimById);
      setIsLoad(false);
      setMessageWarning(resultClaimById.message);
      setIsSuccessMessage(resultClaimById.isSuccess);
      setClaim(resultClaimById.claimDetail);
    } catch (error) {
      console.log('getClaimById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getClaimById();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da Reclamação
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {claim !== null
            ? (
              <div className="detail__container">
                <p className="detail__label">
                  Assunto
                </p>
                <p className="detail__value">
                  {claim.subject}
                </p>

                <p className="detail__label">
                  Produto
                </p>
                <p className="detail__value">
                  {claim.product}
                </p>

                <p className="detail__label">
                  Lote
                </p>
                <p className="detail__value">
                  {claim.lot}
                </p>

                <p className="detail__label">
                  Descrição
                </p>
                <p className="detail__value">
                  {claim.description}
                </p>

                <p className="detail__label">
                  Avaliação do Caso
                </p>
                <p className="detail__value">
                  {claim.case_evaluation}
                </p>

                <p className="detail__label">
                  Tratativa
                </p>
                <p className="detail__value">
                  {claim.negotiation}
                </p>

                <p className="detail__label">
                  Prazo de Comprimento das Ações
                </p>
                <p className="detail__value">
                  {claim.date_completion_actions}
                </p>

                <p className="detail__label">
                  Observação
                </p>
                <p className="detail__value">
                  {claim.observation}
                </p>

                <p className="detail__label">
                  Criado Em
                </p>
                <p className="detail__value">
                  {calendarUSAMask(claim.created)}
                </p>
              </div>
            ) : null }
        </div>
      </div>
    </>
  );
}
