/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import { getToken } from '../../api/auth';
import calendarWithHourAndMinutesMask from '../../utils/masks/calendarWithHourAndMinutesMask';
import { SolicitationsFetch } from '../../api/services/SolicitationAPI';

export default function Solicitation({ history }) {
  const token = getToken();
  const [solicitations, setSolicitations] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getSolicitations() {
      setIsLoad(true);
      try {
        const resultSolicitations = await SolicitationsFetch(token);
        console.log('getSolicitations | resultSolicitations: ', resultSolicitations);
        setIsLoad(false);
        setMessageWarning(resultSolicitations.message);
        setIsSuccessMessage(resultSolicitations.isSuccess);
        if (resultSolicitations.isSuccess) {
          setSolicitations(resultSolicitations.solicitations);
        }
      } catch (error) {
        console.log('getSolicitations | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getSolicitations();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Histórico de Solicitações
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <button
            className="form__button"
            type="button"
            onClick={() => navigate('/solicitations/create', { replace: true })}
          >
            Agendar Solicitação
          </button>

          {solicitations.map((solicitation) => (
            <div
              className="detail__container"
              onClick={() => navigate(`/solicitations/${solicitation.id}`, { replace: true })}
              aria-hidden="true"
            >
              <p className="detail__value">
                {(solicitation.status).toUpperCase()}
              </p>
              {solicitation.description !== null
                ? (
                  <p className="detail__value">
                    {(solicitation.description).toUpperCase()}
                  </p>
                ) : null }
              <p className="detail__value">
                {(calendarWithHourAndMinutesMask(solicitation.created)).toUpperCase()}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
