/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import ProductionList from './components/ProductionList';

import getCropsByYearByYear from '../../utils/getCropsByYear';
import { getToken } from '../../api/auth';

import ProductionsByCropFetch from '../../api/services/ProductionAPI';

export default function Production({ history }) {
  const token = getToken();
  const [crops, setCrops] = useState([]);
  const [cropSelected, setCropSelected] = useState('');
  const [productions, setProductions] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  async function getProductionsByProduct(productId) {
    setIsLoad(true);
    console.log('getProductionsByProduct | productId: ', productId);
    try {
      const resultProductions = await ProductionsByCropFetch(token, productId);
      console.log('getProductions | resultProductions: ', resultProductions);
      setIsLoad(false);
      setMessageWarning(resultProductions.message);
      setIsSuccessMessage(resultProductions.isSuccess);
      setProductions(resultProductions.productions);
    } catch (error) {
      console.log('getProductionsByProduct | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    const cropsByYear = getCropsByYearByYear();
    setCropSelected(cropsByYear[0].name);
    setCrops(cropsByYear);

    getProductionsByProduct(cropsByYear[0].name);
  }, [history]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Produção
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <select
            className="form__select"
            value={cropSelected}
            onChange={(event) => {
              console.log(event.target.value);
              setCropSelected(event.target.value);
              getProductionsByProduct(event.target.value);
            }}
          >
            {
              crops.map((crop) => (
                <option value={crop.name} key={crop.name}>{crop.name}</option>
              ))
            }
          </select>

          {productions.length === 0
            ? (
              <p className="form__message">Não há registro de produção.</p>
            ) : null }

          {
            productions.map((production) => (
              <ProductionList productionParam={production} />
            ))
          }
        </div>
      </div>
    </>
  );
}
