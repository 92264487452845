/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import { getToken } from '../../api/auth';
import { PlotByIdFetch } from '../../api/services/PropertyAPI';

export default function PlotDetail({ history }) {
  const token = getToken();
  const [messageWarning, setMessageWarning] = useState('');
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { plotId } = useParams();

  useEffect(() => {
    async function getPlotById() {
      setIsLoad(true);
      try {
        const resultPlotById = await PlotByIdFetch(token, plotId);
        console.log('getPlotById | resultPlotById: ', resultPlotById);
        setIsLoad(false);
        setMessageWarning(resultPlotById.message);
        setIsSuccessMessage(resultPlotById.isSuccess);
        if (resultPlotById.isSuccess) {
          setPropertyDetail(resultPlotById.plot);
        }
      } catch (error) {
        console.log('getPlotById | error: ', error);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getPlotById();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da Propriedade
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {propertyDetail !== null
            ? (
              <div className="detail__container">
                <p className="detail__label">
                  Nome
                </p>
                <p className="detail__value">
                  {propertyDetail.name}
                </p>

                <p className="detail__label">
                  Coordenada
                </p>
                <p className="detail__value">
                  {propertyDetail.coordinate}
                </p>

                <p className="detail__label">
                  Produção
                </p>
                <p className="detail__value">
                  {propertyDetail.production}
                </p>

                <p className="detail__label">
                  Variedade de Cultivo
                </p>
                <p className="detail__value">
                  {propertyDetail.cultivation_variety}
                </p>

                <p className="detail__label">
                  Safra Atual
                </p>
                <p className="detail__value">
                  {propertyDetail.crop_current}
                </p>

                <p className="detail__label">
                  Produto Químico foi Aplicado ?
                </p>
                <p className="detail__value">
                  {propertyDetail.applied}
                </p>

                <p className="detail__label">
                  Produto Aplicado
                </p>
                <p className="detail__value">
                  {propertyDetail.applied_product}
                </p>
              </div>
            ) : null }

        </div>
      </div>
    </>
  );
}
