export default function ofAgeIsInvalid(dateBirthFormat) {
  const currentDate = new Date();
  // console.log('ofAgeIsInvalid | currentDate: ', currentDate);
  // console.log('ofAgeIsInvalid | dateBirthFormat: ', dateBirthFormat);
  const birthDateSplit = dateBirthFormat.split('/');
  const birthDateTime = new Date(birthDateSplit[2], birthDateSplit[1], birthDateSplit[0]);
  // console.log('ofAgeIsInvalid | birthDateTime : ', birthDateTime);
  const ageMilliseconds = currentDate - birthDateTime;
  // console.log('ofAgeIsInvalid | ageMilliseconds: ', ageMilliseconds);
  const ageYears = ageMilliseconds / 31536000000;
  // console.log('ofAgeIsInvalid | ageYears: ', ageYears);

  if (ageYears >= 18) {
    return false;
  }

  return true;
}
