/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import Load from '../../components/Load';
import NavBar from '../../components/NavBar';

import { getToken } from '../../api/auth';

import { PurchaseCreateFetch } from '../../api/services/NotebookAPI';

export default function PurchaseCreate({ history }) {
  const token = getToken();

  const [invoice, setInvoice] = useState('');
  const [product, setProduct] = useState('');
  const [provider, setProvider] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  async function sendPurchaseCreate(event) {
    event.preventDefault();
    try {
      setIsLoad(true);
      if (!invoice) {
        setMessageWarning('preencha o campo nota fiscal da compra / recibo');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!product) {
        setMessageWarning('preencha o campo produto adquirido');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!provider) {
        setMessageWarning('preencha o campo fornecedor');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else {
        const resultPurchaseCreate = await PurchaseCreateFetch(
          token,
          invoice,
          product,
          provider,
        );
        console.log('sendPurchaseCreate | resultPurchaseCreate', resultPurchaseCreate);
        setIsLoad(false);
        setIsSuccessMessage(resultPurchaseCreate.isSuccess);
        setMessageWarning(resultPurchaseCreate.message);
      }
    } catch (error) {
      console.log('sendPurchaseCreate | error', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Registrar Compra
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {!isSuccessMessage
            ? (
              <>
                <div className="label__container">
                  <p className="form__label">
                    Nota Fiscal da Compra / Recibo *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a nota fiscal da compra / recibo"
                  value={invoice}
                  onChange={(event) => setInvoice(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Produto Adquirido *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o produto adquirido"
                  value={product}
                  onChange={(event) => setProduct(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Fornecedor *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o fornecedor"
                  value={provider}
                  onChange={(event) => setProvider(event.target.value)}
                />

                <button
                  className="form__button"
                  type="button"
                  onClick={(event) => sendPurchaseCreate(event)}
                >
                  Resgistrar
                </button>
              </>
            ) : null }

          {isSuccessMessage && messageWarning
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => navigate('/notebook/purchase', { replace: true })}
              >
                Ir para compras
              </button>
            ) : null }
        </div>
      </div>
    </>
  );
}
