export default function cpfMask(value) {
  const cleanValue = value.replace(/\D/g, '').substring(0, 11);

  let finalValue = cleanValue.substring(0, 3);
  const part2 = cleanValue.substring(3, 6);
  if (part2) {
    finalValue += `.${part2}`;
    const part3 = cleanValue.substring(6, 9);
    if (part3) {
      finalValue += `.${part3}`;
      const part4 = cleanValue.substring(9, 11);
      if (part4) {
        finalValue += `-${part4}`;
      }
    }
  }

  return finalValue;
}
