/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
export function addMoneyMask(amount) {
  let decimalCount = 2;
  const decimal = ',';
  const thousands = '.';

  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? '-' : '';

  const amountInteger = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
  ).toString();
  const amountLength = amountInteger.length > 3 ? amountInteger.length % 3 : 0;

  return `R$ ${negativeSign}${
    amountLength ? amountInteger.substr(0, amountLength) + thousands : ''
  }${amountInteger
    .substr(amountLength)
    .replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}${
    decimalCount
      ? decimal
          + Math.abs(amount - amountInteger)
            .toFixed(decimalCount)
            .slice(2)
      : ''
  }`;
}

export function addMoneyMaskInput(text) {
  console.log(text);
  const textLength = text.length;
  console.log('textLength: ', textLength);

  const textCentsSubs = text.substring(textLength - 2, textLength);
  console.log('textCentsSubs: ', textCentsSubs);

  const textCents = (parseInt(text) < 10 ? 0 + textCentsSubs : textCentsSubs);
  console.log('textCents: ', textCents);

  if (textLength < 3) {
    console.log('if textCents < 2');
    return `R$ 0,${textCents}`;
  }

  let loop = 0;
  const loopsByTextLength = (textLength - 2) / 3;
  console.log('loopsByTextLength: ', loopsByTextLength);

  let textSubs = `,${textCents}`;

  while (loop < loopsByTextLength) {
    console.log('======================================================');
    console.log('loop: ', loop);
    const textIndexSubsEnd = textLength - ((3 * loop) + 2);
    console.log('textIndexSubsEnd: ', textIndexSubsEnd);
    const textIndexSubsStart = textIndexSubsEnd - 3;
    console.log('textIndexSubsStart ', textIndexSubsStart);

    const digits = text.substring(textIndexSubsStart, textIndexSubsEnd);
    console.log('digits: ', digits);

    textSubs = `${digits}${loop > 0 ? '.' : ''}${textSubs}`;
    console.log('textSubs: ', textSubs);
    loop += 1;
  }

  return `R$ ${textSubs}`;
}

export function removeMoneyMask(value) {
  return value
    .replace('.', '')
    .replace(',', '.')
    .replace('R$', '')
    .replace(' ', '');
}
