/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import date from 'date-and-time';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';

import { addCpfCnpjMask, removeCpfOrCnpjMask } from '../../utils/masks/manipulateCpfOrCnpjMask';
import phoneMask from '../../utils/masks/phoneMask';
import cepMask from '../../utils/masks/cepMask';
import dateTimeMask from '../../utils/masks/dateTimeMask';
import ofAgeIsInvalid from '../../utils/validation/ofAgeIsInvalid';
import emailIsInvalid from '../../utils/validation/emailIsInvalid';
import itemsByAlphabeticalOrder from '../../utils/itemsByAlphabeticalOrder';
import censorshipMask from '../../utils/masks/censorshipMask';

import { ProfileFetch, ProfileUpdateFetch } from '../../api/services/UserAPI';

const cities = require('../../utils/resource/cities.json');
const ufs = require('../../utils/resource/ufs.json');

const citiesInAlphabeticalOrder = itemsByAlphabeticalOrder(cities, 'name');
const ufsInAlphabeticalOrder = itemsByAlphabeticalOrder(ufs, 'name');

export default function Profile({ history }) {
  const token = getToken();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [ufSelected, setUfSelected] = useState(ufsInAlphabeticalOrder[0]);
  const citiesFilteredByUf = citiesInAlphabeticalOrder.filter(
    (city) => city.uf_id === ufsInAlphabeticalOrder[0].id,
  );
  // console.log('Profile | citiesFilteredByUf: ', citiesFilteredByUf);
  const [citySelected, setCitySelected] = useState(citiesFilteredByUf[0]);
  const [citiesByUf, setCitiesByUf] = useState(citiesFilteredByUf);
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneWhatsapp, setPhoneWhatsapp] = useState('');
  const [district, setDistrict] = useState('');
  const [cep, setCep] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  function changeMaskCpfCnpjAndCensorship(textValue) {
    console.log('onChangeText | textValue: ', textValue);
    const textCpfCnpj = addCpfCnpjMask(textValue);
    console.log('onChangeText | textCpfCnpj: ', textCpfCnpj);
    const textCpfCnpjWithCensorship = censorshipMask(textCpfCnpj, 60);
    console.log('onChangeText | textCpfCnpj: ', textCpfCnpj);
    return textCpfCnpjWithCensorship;
  }

  function isUfFound(ufLoop, userUf) {
    const ufUpperCase = (ufLoop.uf).toUpperCase();
    console.log('isUfFound | ufUpperCase, userUf: ', ufUpperCase, userUf);
    return ufUpperCase === userUf;
  }

  function isCityFound(cityLoop, userCity) {
    const cityUpperCase = (cityLoop.name).toUpperCase();
    console.log('isCityFound | cityUpperCase, userCity: ', cityUpperCase, userCity);
    return cityUpperCase === userCity;
  }

  useEffect(() => {
    async function getProfile() {
      setIsLoad(true);
      try {
        const resultProfile = await ProfileFetch(token);
        console.log('getProfile | resultProfile: ', resultProfile);
        setIsLoad(false);
        setIsSuccessMessage(resultProfile.isSuccess);
        setMessageWarning(resultProfile.message);
        if (resultProfile.isSuccess) {
          setName(resultProfile.user.name);
          setEmail(resultProfile.user.email);
          setCpfCnpj(resultProfile.user.cpf_cnpj);
          setBirthDate(dateTimeMask(resultProfile.user.birth_date));

          const ufFound = ufsInAlphabeticalOrder.find(
            (ufElement) => isUfFound(ufElement, resultProfile.user.state),
          );
          console.log('getProfile | ufFound: ', ufFound);
          setUfSelected(ufFound.uf);

          const citiesFilteredByUfUpdate = citiesInAlphabeticalOrder.filter(
            (city) => city.uf_id === ufFound.id,
          );
          console.log('getProfile | citiesFilteredByUfUpdate: ', citiesFilteredByUfUpdate);
          setCitiesByUf(citiesFilteredByUfUpdate);

          const cityFound = citiesFilteredByUfUpdate.find(
            (ufElement) => isCityFound(ufElement, resultProfile.user.city),
          );
          console.log('getProfile | cityFound: ', cityFound);
          setCitySelected(cityFound.name);

          setAddress(resultProfile.user.address);
          setPhone(resultProfile.user.phone);
          setPhoneWhatsapp(resultProfile.user.whatsapp);
          setDistrict(resultProfile.user.district);
          setCep(resultProfile.user.cep);
        }
      } catch (error) {
        console.log('getProfile | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getProfile();
  }, [history, token]);

  function changeCep(textValue) {
    // console.log('changeCep | textValue: ', textValue);
    const textCep = cepMask(textValue);
    // console.log('changeCep | textCep: ', textCep);
    setCep(textCep);
  }

  function changeDateTime(textValue) {
    console.log('changePhone | textValue: ', textValue);
    const texDateTime = dateTimeMask(textValue);
    console.log('onChangeText | texDateTime: ', texDateTime);
    setBirthDate(texDateTime);
  }

  async function sendProfileUpdate(event) {
    event.preventDefault();
    try {
      setIsLoad(true);
      const nameWithoutTrim = name.trim();
      setName(nameWithoutTrim);
      const isValidBirthDate = date.isValid(birthDate, 'DD/MM/YYYY');

      if (!name) {
        setMessageWarning('Preencha o campo nome');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (name.length < 3) {
        setMessageWarning('Campo nome completo é inválido');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (cpfCnpj.length === 14 && birthDate.length < 10) {
        setMessageWarning('Preencha o campo data de nascimento');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!isValidBirthDate) {
        setMessageWarning('Campo data de nascimento é inválido');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (cpfCnpj.length === 14 && ofAgeIsInvalid(birthDate)) {
        setMessageWarning('É necessário ser maior de idade');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (email && emailIsInvalid(email)) {
        setMessageWarning('Campo e-mail é inválido');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!phone) {
        setMessageWarning('Preencha o campo telefone');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (phone.length < 14) {
        console.log('sendProfileUpdate | phone: ', phone);
        console.log('sendProfileUpdate | phone.length: ', phone.length);
        setMessageWarning('Campo telefone é inválido');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!phoneWhatsapp) {
        setMessageWarning('Preencha o campo whatsapp');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (phoneWhatsapp.length < 14) {
        setMessageWarning('Campo whatsapp é inválido');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!address) {
        setMessageWarning('Preencha o campo endereço');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else {
        const resultProfileUpdate = await ProfileUpdateFetch(
          token,
          nameWithoutTrim,
          removeCpfOrCnpjMask(cpfCnpj),
          birthDate,
          email,
          phone,
          phoneWhatsapp,
          address,
          district,
          (citySelected).toUpperCase(),
          ufSelected,
          cep,
        );
        console.log('sendProfileUpdate | resultProfileUpdate', resultProfileUpdate);
        setIsLoad(false);
        setIsSuccessMessage(resultProfileUpdate.isSuccess);
        setMessageWarning(resultProfileUpdate.message);
      }
    } catch (error) {
      console.log('sendProfileUpdate | error', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Informações Pessoais
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <div className="label__container">
            <p className="form__label">
              Nome Completo *
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui seu nome completo"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />

          <div className="label__container">
            <p className="form__label">
              CPF / CNPJ *
            </p>
          </div>
          <div className="form__info">
            <p>
              {changeMaskCpfCnpjAndCensorship(cpfCnpj)}
            </p>
          </div>

          <div className="label__container">
            <p className="form__label">
              Data de Nascimento
              {cpfCnpj.length === 14 ? ' *' : null}
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui sua data de nascimento"
            value={birthDate}
            maxLength={10}
            onChange={(event) => changeDateTime(event.target.value)}
          />

          <p className="form__description">
            Informações para Contato
          </p>

          <div className="label__container">
            <p className="form__label">
              E-mail
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui seu e-mail"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <div className="label__container">
            <p className="form__label">
              Telefone *
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui seu telefone"
            value={phone}
            maxLength={15}
            onChange={(event) => setPhone(phoneMask(event.target.value))}
          />

          <div className="label__container">
            <p className="form__label">
              WhatsApp *
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui seu whatsapp"
            value={phoneWhatsapp}
            maxLength={15}
            onChange={(event) => setPhoneWhatsapp(phoneMask(event.target.value))}
          />

          <p className="form__description">
            Informações de Localização
          </p>

          <div className="label__container">
            <p className="form__label">
              Endereço (Rua/Av/Travessa, número) *
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui seu endereço"
            value={address}
            onChange={(event) => setAddress(event.target.value)}
          />

          <div className="label__container">
            <p className="form__label">
              Estado *
            </p>
          </div>
          <select
            name="ufs"
            className="form__select"
            value={ufSelected}
            onChange={(event) => {
              console.log('select | uf | event.target.value: ', event.target.value);
              setUfSelected(event.target.value);
              const ufFound = ufsInAlphabeticalOrder.find((uf) => uf.uf === event.target.value);
              const citiesByUfUpdate = citiesInAlphabeticalOrder.filter(
                (city) => city.uf_id === ufFound.id,
              );
              console.log('onVisibleModelChoices | citiesByUfUpdate: ', citiesByUfUpdate);
              setCitiesByUf(citiesByUfUpdate);
              setCitySelected(citiesByUfUpdate[0]);
            }}
          >
            {
              ufsInAlphabeticalOrder.map((uf) => (
                <option value={uf.uf} key={uf.uf}>
                  {uf.uf}
                </option>
              ))
            }
          </select>

          <div className="label__container">
            <p className="form__label">
              Cidade *
            </p>
          </div>
          <select
            name="cities"
            className="form__select"
            value={citySelected}
            onChange={(event) => {
              console.log('select | city | event.target.value: ', event.target.value);
              setCitySelected(event.target.value);
            }}
          >
            {
              citiesByUf.map((uf) => (
                <option value={uf.name} key={uf.name}>
                  {uf.name}
                </option>
              ))
            }
          </select>

          <div className="label__container">
            <p className="form__label">
              Bairro
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui seu bairro"
            value={district}
            onChange={(event) => setDistrict(event.target.value)}
          />

          <div className="label__container">
            <p className="form__label">
              CEP
            </p>
          </div>
          <input
            className="form__input"
            type="text"
            placeholder="Digite aqui seu CEP"
            value={cep}
            maxLength={9}
            onChange={(event) => changeCep(event.target.value)}
          />

          <button
            className="form__button"
            type="button"
            onClick={(event) => sendProfileUpdate(event)}
          >
            Salvar
          </button>
          <button
            className="form__button--outline"
            type="button"
            onClick={() => navigate('/redefine-password', { replace: true })}
          >
            Redefinir Senha
          </button>
        </div>
      </div>
    </>
  );
}
