/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarUSAMask from '../../utils/masks/calendarUSAMask';
import weightMask from '../../utils/masks/weightMask';

import { CropByIdFetch } from '../../api/services/NotebookAPI';

export default function CropDetail({ history }) {
  const token = getToken();

  const [crop, setCrop] = useState(null);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { cropId } = useParams();

  async function getCropById() {
    setIsLoad(true);
    try {
      const resultCropById = await CropByIdFetch(token, cropId);
      console.log('getCropById | resultCropById: ', resultCropById);
      setIsLoad(false);
      setMessageWarning(resultCropById.message);
      setIsSuccessMessage(resultCropById.isSuccess);
      setCrop(resultCropById.cropDetail);
    } catch (error) {
      console.log('getCropById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getCropById();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da Colheita
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {crop !== null
            ? (
              <div className="detail__container">
                <p className="detail__label">
                  Matéria-Prima Colhida
                </p>
                <p className="detail__value">
                  {crop.feedstock}
                </p>

                <p className="detail__label">
                  Quantidade
                </p>
                <p className="detail__value">
                  {weightMask(crop.quantity)}
                </p>

                <p className="detail__label">
                  Quantidade Descartada
                </p>
                <p className="detail__value">
                  {weightMask(crop.quantity_discarded)}
                </p>

                <p className="detail__label">
                  Talhão
                </p>
                <p className="detail__value">
                  {crop.plot}
                </p>

                <p className="detail__label">
                  Observação
                </p>
                <p className="detail__value">
                  {crop.observation}
                </p>

                <p className="detail__label">
                  Criado Em
                </p>
                <p className="detail__value">
                  {calendarUSAMask(crop.created)}
                </p>
              </div>
            ) : null }
        </div>
      </div>
    </>
  );
}
