/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import { getToken } from '../../api/auth';

import Load from '../../components/Load';
import NavBar from '../../components/NavBar';
import LoanList from './components/LoanList';

import { FinancialsFetch } from '../../api/services/FinancialAPI';

export default function Financial(history) {
  const token = getToken();
  const [loans, setLoans] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  async function getLoans() {
    setIsLoad(true);
    try {
      const resultFinancials = await FinancialsFetch(token);
      console.log('getLoans | resultFinancials: ', resultFinancials);
      setIsLoad(false);
      setMessageWarning(resultFinancials.message);
      setIsSuccessMessage(resultFinancials.isSuccess);
      setLoans(resultFinancials.loans);
    } catch (error) {
      console.log('getLoans | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getLoans();
  }, [history, token]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Antecipações
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <button
            className="form__button"
            type="button"
            // eslint-disable-next-line no-undef
            onClick={() => navigate('/financial/create', { replace: true })}
          >
            Registrar Antecipação
          </button>

          {loans.length === 0
            ? (
              <p className="form__message">Não há registro de antecipação.</p>
            ) : null }

          {loans.map((loan) => (
            <LoanList
              loanParam={loan}
              onAction={() => navigate('/financial', { replace: true })}
            />
          ))}
        </div>
      </div>
    </>
  );
}
