/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarUSAMask from '../../utils/masks/calendarUSAMask';
import weightMask from '../../utils/masks/weightMask';

import { OrganicChestnutsFetch } from '../../api/services/NotebookAPI';

export default function OrganicChestnut({ history }) {
  const token = getToken();
  const [organicChestnuts, setOrganicChestnuts] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getOrganicChestnuts() {
      setIsLoad(true);
      try {
        const resultOrganicChestnuts = await OrganicChestnutsFetch(token);
        console.log('getOrganicChestnuts | resultOrganicChestnuts: ', resultOrganicChestnuts);
        setIsLoad(false);
        setMessageWarning(resultOrganicChestnuts.message);
        setIsSuccessMessage(resultOrganicChestnuts.isSuccess);
        if (resultOrganicChestnuts.isSuccess) {
          setOrganicChestnuts(resultOrganicChestnuts.organicChestnuts);
        }
      } catch (error) {
        console.log('getOrganicChestnuts | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getOrganicChestnuts();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Histórico de Castanhas Orgânicas
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <button
            className="form__button"
            type="button"
            // eslint-disable-next-line no-undef
            onClick={() => navigate('/notebook/organic-chestnut/create', { replace: true })}
          >
            Registrar Castanha Orgânica
          </button>

          {organicChestnuts.map((organicChestnut) => (
            <div
              className="detail__container"
              onClick={() => navigate(`/notebook/organic-chestnut/${organicChestnut.id}`, { replace: true })}
              aria-hidden="true"
            >
              <p className="detail__value">
                {weightMask(organicChestnut.weight)}
              </p>
              <p className="detail__value">
                {calendarUSAMask(organicChestnut.created)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
