/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import NewsDetailCard from './components/NewsDetailCard';

import { NewsByIdFetch } from '../../api/services/NewsAPI';

export default function NewsDetail({ history }) {
  const [newsDetail, setNewsDetail] = useState(null);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { newsId } = useParams();

  async function getNewsById() {
    setIsLoad(true);
    try {
      const resultNewsById = await NewsByIdFetch(newsId);
      console.log('getNewsById | resultNewsById: ', resultNewsById);
      setIsLoad(false);
      setMessageWarning(resultNewsById.message);
      setIsSuccessMessage(resultNewsById.isSuccess);
      setNewsDetail(resultNewsById.news);
    } catch (error) {
      console.log('getNewsById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getNewsById();
  }, [history]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da noticia
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {newsDetail !== null ? (
            <>
              <img
                src={newsDetail.image}
                alt="LogoImage"
                className="banner_image"
              />

              <NewsDetailCard newsParam={newsDetail} />
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
