/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export async function LevelsFetch() {
  console.log('LevelsFetch');
  const configRequest = {
    method: 'get',
    url: '/levels',
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
    );
    console.log('LevelsFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      levels: response.data,
    };
  } catch (error) {
    console.log('LevelsFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',

    };
  }
}
export async function LevelUserFetch(tokenAuthorization) {
  console.log('LevelUserFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/users/user_level',
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('LevelUserFetch | response.data.level: ', response.data.level);
    return {
      isSuccess: true,
      message: '',
      level: response.data.level,
    };
  } catch (error) {
    console.log('LevelUserFetch | error: ', error.message);
    let message;
    if (error.message === 'Request failed with status code 400') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        { ...configRequest.headers.headers },
      );
    }
    return {
      isSuccess: false,
      message,
      level: null,
    };
  }
}
