/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import Load from '../../components/Load';
import NavBar from '../../components/NavBar';

import { getToken } from '../../api/auth';
import removeLetter from '../../utils/masks/removeLetter';
import removeSpecial from '../../utils/masks/removeSpecial';

import { CropCreateFetch } from '../../api/services/NotebookAPI';
import { PlotsFetch } from '../../api/services/PropertyAPI';

export default function CropCreate({ history }) {
  const token = getToken();

  const [plots, setPlots] = useState([]);
  const [plotSelected, setPlotSelected] = useState({ name: '' });
  const [feedstock, setFeedstock] = useState('');
  const [quantity, setQuantity] = useState('');
  const [quantityDiscarded, setQuantityDiscarded] = useState('');
  const [observation, setObservation] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getPlots() {
      setIsLoad(true);
      try {
        const resultPlots = await PlotsFetch(token);
        console.log('getPlots | resultPlots: ', resultPlots);
        setIsLoad(false);
        setMessageWarning(resultPlots.message);
        if (resultPlots.isSuccess) {
          setPlots(resultPlots.plots);
          if ((resultPlots.plots).length >= 1) {
            setPlotSelected(resultPlots.plots[0]);
          }
        }
      } catch (error) {
        console.log('getPlots | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getPlots();
  }, [history, token]);

  async function sendCropCreate(event) {
    event.preventDefault();
    try {
      setIsLoad(true);
      if (plotSelected.name === '') {
        setMessageWarning('selecione um talhão');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!feedstock) {
        setMessageWarning('preencha o campo matéria-prima colhida');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!quantity) {
        setMessageWarning('preencha o campo quantidade');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!quantityDiscarded) {
        setMessageWarning('preencha o campo dosagem');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else {
        const resultCropCreate = await CropCreateFetch(
          token,
          feedstock,
          quantity,
          quantityDiscarded,
          observation,
          plotSelected.name,
        );
        console.log('sendCropCreate | resultCropCreate', resultCropCreate);
        setIsLoad(false);
        setIsSuccessMessage(resultCropCreate.isSuccess);
        setMessageWarning(resultCropCreate.message);
      }
    } catch (error) {
      console.log('sendCropCreate | error', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Registrar Colheita
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {!isSuccessMessage
            ? (
              <>
                <div className="label__container">
                  <p className="form__label">
                    Talhão *
                  </p>
                </div>
                <select
                  className="form__select"
                  value={plotSelected.name}
                  onChange={(event) => {
                    console.log(event.target.value);
                    function isPlot(plot) {
                      return plot.name === event.target.value;
                    }
                    const plotFound = plots.find(isPlot);
                    console.log('plotFound: ', event.target.value);
                    setPlotSelected(plotFound);
                  }}
                >
                  {
                    plots.map((plot) => (
                      <option value={plot.name} key={plot.name}>{plot.name}</option>
                    ))
                  }
                </select>

                <div className="label__container">
                  <p className="form__label">
                    Matéria-Prima Colhida *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a matéria-prima colhida"
                  value={feedstock}
                  onChange={(event) => setFeedstock(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Quantidade (KG) *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a dosagem"
                  value={quantity}
                  onChange={(event) => {
                    const quantityWithoutLetter = removeLetter(event.target.value);
                    console.log('onChange | quantity | quantityWithoutLetter', quantityWithoutLetter);
                    const quantityWithoutSpecial = removeSpecial(quantityWithoutLetter);
                    console.log('onChange | quantity | quantityWithoutSpecial', quantityWithoutSpecial);
                    setQuantity(quantityWithoutSpecial);
                  }}
                />

                <div className="label__container">
                  <p className="form__label">
                    Quantidade Descartada (KG) *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a quantidade descartada"
                  value={quantityDiscarded}
                  onChange={(event) => {
                    const quantityDiscardedWithoutLetter = removeLetter(event.target.value);
                    console.log('onChange | quantityDiscarded | quantityDiscardedWithoutLetter', quantityDiscardedWithoutLetter);
                    const quantityDiscardedWithoutSpecial = removeSpecial(
                      quantityDiscardedWithoutLetter,
                    );
                    console.log('onChange | quantityDiscarded | quantityDiscardedWithoutSpecial', quantityDiscardedWithoutSpecial);
                    setQuantityDiscarded(quantityDiscardedWithoutSpecial);
                  }}
                />

                <div className="label__container">
                  <p className="form__label">
                    Observação
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a observação"
                  value={observation}
                  onChange={(event) => setObservation(event.target.value)}
                />

                <button
                  className="form__button"
                  type="button"
                  onClick={(event) => sendCropCreate(event)}
                >
                  Resgistrar
                </button>
              </>
            ) : null }

          {isSuccessMessage && messageWarning
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => navigate('/notebook/crop', { replace: true })}
              >
                Ir para colheitas
              </button>
            ) : null }
        </div>
      </div>
    </>
  );
}
