import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export async function BankAccountFetch(tokenAuthorization) {
  console.log('BankAccountFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/bank_accounts/read_bank_account',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('BankAccountFetch | response', response.data);
    return {
      isSuccess: true,
      message: '',
      bankAccount: response.data.bank_account,
    };
  } catch (error) {
    console.log('BankAccountFetch | error:', error);
    let message;
    if (error.message === 'Request failed with status code 401') {
      message = error.response.data.message;
    } else if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.body,
      );
    }
    return {
      isSuccess: false,
      message,
    };
  }
}

export async function BankAccountCreateFetch(
  tokenAuthorization,
  bankNumber,
  bankName,
  agency,
  account,
  accountType,
  holderName,
  holderCpfCnpj,
) {
  console.log(
    'BankAccountCreateFetch | tokenAuthorization, bankNumber, bankName, agency, account, accountType, holderName, holderCpfCnpj: ',
    tokenAuthorization,
    bankNumber,
    bankName,
    agency,
    account,
    accountType,
    holderName,
    holderCpfCnpj,
  );
  const configRequest = {
    method: 'post',
    url: '/bank_accounts/create_bank_account',
    body: {
      bank_number: bankNumber,
      bank_name: bankName,
      agency,
      account,
      account_type: accountType,
      holder_name: holderName,
      holder_cpf_cnpj: holderCpfCnpj,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('BankAccountCreateFetch | response', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('BankAccountCreateFetch | error:', error);
    let message;
    if (error.message === 'Request failed with status code 401') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.body,
      );
    }

    return {
      isSuccess: false,
      message,
    };
  }
}

export async function BankAccountUpdateFetch(
  tokenAuthorization,
  bankNumber,
  bankName,
  agency,
  account,
  accountType,
  holderName,
  holderCpfCnpj,
) {
  console.log(
    'BankAccountCreate | tokenAuthorization, bankNumber, bankName, agency, account, accountType, holderName, holderCpfCnpj: ',
    tokenAuthorization,
    bankNumber,
    bankName,
    agency,
    account,
    accountType,
    holderName,
    holderCpfCnpj,
  );
  const configRequest = {
    method: 'patch',
    url: '/bank_accounts/update_bank_account',
    body: {
      bank_number: bankNumber,
      bank_name: bankName,
      agency,
      account,
      account_type: accountType,
      holder_name: holderName,
      holder_cpf_cnpj: holderCpfCnpj,
    },
    headers: { headers: { Authorization: tokenAuthorization } },
  };
  console.log('BankAccountUpdateFetch | configRequest.body:', configRequest.body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('BankAccountUpdateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('BankAccountUpdateFetch | error:', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      {
        ...configRequest.headers.headers,
        ...configRequest.body,
      },
    );

    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function BankAccountCheckAllowFetch(tokenAuthorization) {
  console.log('BankAccountCheckAllowFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/bank_accounts/check_user_permission',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('BankAccountCheckAllowFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: '',
      isAllowed: response.data.has_permission,
    };
  } catch (error) {
    console.log('BankAccountCheckAllowFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401') {
      console.log('BankAccountCheckAllowFetch | error.response.data:', error.response.data);
      message = error.response.data.message;
    } else {
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        configRequest.headers.headers,
      );
      message = 'no momento esse recurso está indisponível, tente novamente mais tarde.';
    }
    return {
      isSuccess: false,
      message,
      isAllowed: false,
    };
  }
}
