export default function getCropsByYearByYear() {
  const date = new Date();
  console.log('getCropsByYear | date.getFullYear: ', date.getFullYear());

  let cropsYearInitial = 2014;
  const cropsList = [];

  try {
    while (cropsYearInitial < date.getFullYear()) {
      cropsYearInitial += 1;
      cropsList.push(cropsYearInitial);
    }

    console.log('getCropsByYear | cropsYearInitial: ', cropsYearInitial);
    console.log('getCropsByYear | cropsList: ', cropsList);
    console.log('getCropsByYear | cropsList: ', cropsList.reverse());

    const cropsListString = cropsList.map((crop, index, cropsArray) => {
      const nextIndex = index + 1;
      if (nextIndex < cropsArray.length) {
        return { name: `${cropsArray[index + 1]}/${crop}` };
      }
      return { name: `${crop - 1}/${crop}` };
    });

    console.log('getCropsByYear | cropsListString: ', cropsListString);
    return cropsListString;
  } catch (error) {
    console.log('getCropsByYear | error: ', error);
    return [{ name: `${cropsYearInitial}/${cropsYearInitial - 1}` }];
  }
}
