/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

export default function PropertyList({ propertyParam, onAction }) {
  return (
    <button
      className="detail__container"
      type="button"
      onClick={() => onAction()}
    >
      {propertyParam.name}
    </button>
  );
}
