/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import date from 'date-and-time';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import { IoEye, IoEyeOff } from 'react-icons/io5';

import './styles.css';

import Load from '../../components/Load';

import { addCpfCnpjMask, removeCpfOrCnpjMask } from '../../utils/masks/manipulateCpfOrCnpjMask';
import removeSpecial from '../../utils/masks/removeSpecial';
import phoneMask from '../../utils/masks/phoneMask';
import cepMask from '../../utils/masks/cepMask';
import dateTimeMask from '../../utils/masks/dateTimeMask';
import ofAgeIsInvalid from '../../utils/validation/ofAgeIsInvalid';
import emailIsInvalid from '../../utils/validation/emailIsInvalid';
import passwordIsInvalid from '../../utils/validation/passwordIsInvalid';
import itemsByAlphabeticalOrder from '../../utils/itemsByAlphabeticalOrder';

import { SignUpFetch } from '../../api/services/UserAPI';
import TermOfUseFetch from '../../api/services/TermOfUseAPI';

const cities = require('../../utils/resource/cities.json');
const ufs = require('../../utils/resource/ufs.json');

const citiesInAlphabeticalOrder = itemsByAlphabeticalOrder(cities, 'name');
const ufsInAlphabeticalOrder = itemsByAlphabeticalOrder(ufs, 'name');

export default function SignUp({ history }) {
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [ufSelected, setUfSelected] = useState(ufsInAlphabeticalOrder[0]);
  const citiesFilteredByUf = citiesInAlphabeticalOrder.filter(
    (city) => city.uf_id === ufsInAlphabeticalOrder[0].id,
  );
  console.log('SignUp | citiesFilteredByUf: ', citiesFilteredByUf);
  const [citySelected, setCitySelected] = useState(citiesFilteredByUf[0].name);
  const [citiesByUf, setCitiesByUf] = useState(citiesFilteredByUf);
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneWhatsapp, setPhoneWhatsapp] = useState('');
  const [district, setDistrict] = useState('');
  const [cep, setCep] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [stepFormSelected, setStepFormSelected] = useState('personal-information');
  const [urlTermOfUse, setUrlTermOfUse] = useState('');
  const [isAcceptedTermsOfUse, setIsAcceptedTermsOfUse] = useState(false);
  const [isPasswordSecure, setIsPasswordSecure] = useState(true);
  const [isPasswordConfirmSecure, setIsPasswordConfirmSecure] = useState(true);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    async function getTermOfUse() {
      setIsLoad(true);

      try {
        const resultTermOfUse = await TermOfUseFetch();
        console.log('getTermOfUse | resultTermOfUse: ', resultTermOfUse);
        setIsLoad(false);

        if (resultTermOfUse.isSuccess) {
          setUrlTermOfUse(resultTermOfUse.termOfUse);
        } else {
          setIsSuccessMessage(resultTermOfUse.isSuccess);
          setMessageWarning(resultTermOfUse.message);
        }
      } catch (error) {
        console.log('getTermOfUse | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getTermOfUse();
  }, [history]);

  function changeMaskCpfCnpj(textValue) {
    console.log('onChangeText | textValue: ', textValue);
    const textClean = removeCpfOrCnpjMask(textValue);
    console.log('onChangeText | textClean: ', textClean);
    const textCpfCnpj = addCpfCnpjMask(textClean);
    console.log('onChangeText | textCpfCnpj: ', textCpfCnpj);
    setCpfCnpj(textCpfCnpj);
  }

  function changeCep(textValue) {
    // console.log('changeCep | textValue: ', textValue);
    const textCep = cepMask(textValue);
    // console.log('changeCep | textCep: ', textCep);
    setCep(textCep);
  }

  function changeDateTime(textValue) {
    console.log('changePhone | textValue: ', textValue);
    const texDateTime = dateTimeMask(textValue);
    console.log('onChangeText | texDateTime: ', texDateTime);
    setBirthDate(texDateTime);
  }

  async function sendSignUp(event) {
    event.preventDefault();
    setIsLoad(true);
    const nameWithoutTrim = name.trim();
    setName(nameWithoutTrim);
    try {
      const resultSignUp = await SignUpFetch(
        username,
        nameWithoutTrim,
        removeCpfOrCnpjMask(cpfCnpj),
        birthDate,
        email,
        phone,
        phoneWhatsapp,
        address,
        district,
        (citySelected).toUpperCase(),
        ufSelected,
        cep,
        password,
      );
      // console.log('sendSignUp | resultSignUp: ', resultSignUp);
      setIsLoad(false);
      setIsSuccessMessage(resultSignUp.isSuccess);
      setMessageWarning(resultSignUp.message);
    } catch (error) {
      console.log('sendSignUp | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  return (
    <div className="container">
      <Load isShow={isLoad} />
      <div className="form">
        <p className="form__header">
          Cadastro
        </p>
        {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

        {stepFormSelected === 'personal-information'
          ? (
            <>
              <p className="form__description">
                Informações Pessoais
              </p>

              <div className="label__container">
                <p className="form__label">
                  Nome Completo *
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui seu nome completo"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />

              <div className="label__container">
                <p className="form__label">
                  Nome de Usuário *
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui o seu nome de usuário"
                value={username}
                onChange={(event) => {
                  const textWithoutTrim = (event.target.value).replace(/ /g, '');
                  const textWithoutSpecial = removeSpecial(textWithoutTrim);
                  setUsername(textWithoutSpecial);
                }}
              />

              <div className="label__container">
                <p className="form__label">
                  CPF / CNPJ *
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui seu CPF/CNPJ"
                value={cpfCnpj}
                maxLength={14} // tinha 18
                onChange={(event) => changeMaskCpfCnpj(event.target.value)}
              />

              <div className="label__container">
                <p className="form__label">
                  Data de Nascimento
                  {cpfCnpj.length === 14 ? ' *' : null}
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui sua data de nascimento"
                value={birthDate}
                maxLength={10}
                onChange={(event) => changeDateTime(event.target.value)}
              />

              <button
                className="form__button"
                type="button"
                onClick={() => {
                  const isValidBirthDate = date.isValid(birthDate, 'DD/MM/YYYY');
                  const isValidCpf = cpfValidator.isValid(cpfCnpj);
                  const isValidCnpj = cnpjValidator.isValid(cpfCnpj);

                  if (!name) {
                    setMessageWarning('Preencha o campo nome');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (!username) {
                    setMessageWarning('Preencha o campo nome de usuário');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (name.length < 3) {
                    setMessageWarning('Campo nome completo é inválido');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (!cpfCnpj || cpfCnpj.length < 14) {
                    setMessageWarning('Preencha o campo CPF / CNPJ');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (cpfCnpj.length === 14 && !isValidCpf) {
                    setMessageWarning('CPF é inválido');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (cpfCnpj.length > 14 && !isValidCnpj) {
                    setMessageWarning('CNPJ é inválido');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (cpfCnpj.length === 14 && birthDate.length < 10) {
                    setMessageWarning('Preencha o campo data de nascimento');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (!isValidBirthDate) {
                    setMessageWarning('Campo data de nascimento é inválido');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (cpfCnpj.length === 14 && ofAgeIsInvalid(birthDate)) {
                    setMessageWarning('É necessário ser maior de idade');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else {
                    setMessageWarning('');
                    setStepFormSelected('contact-information');
                  }
                }}
              >
                Próxima Etapa
              </button>
            </>
          ) : null }

        {stepFormSelected === 'contact-information'
          ? (
            <>
              <p className="form__description">
                Informações para Contato
              </p>

              <div className="label__container">
                <p className="form__label">
                  E-mail
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui seu e-mail"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />

              <div className="label__container">
                <p className="form__label">
                  Telefone *
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui seu telefone"
                value={phone}
                maxLength={15}
                onChange={(event) => setPhone(phoneMask(event.target.value))}
              />

              <div div className="label__container">
                <p className="form__label">
                  WhatsApp *
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui seu whatsapp"
                value={phoneWhatsapp}
                maxLength={15}
                onChange={(event) => setPhoneWhatsapp(phoneMask(event.target.value))}
              />

              <button
                className="form__button"
                type="button"
                onClick={() => {
                  if (email && emailIsInvalid(email)) {
                    setMessageWarning('Campo e-mail é inválido');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (!phone) {
                    setMessageWarning('Preencha o campo telefone');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (phone.length < 14) {
                    setMessageWarning('Campo telefone é inválido');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (!phoneWhatsapp) {
                    setMessageWarning('Preencha o campo whatsapp');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (phoneWhatsapp.length < 14) {
                    setMessageWarning('Campo whatsapp é inválido');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else {
                    setMessageWarning('');
                    setStepFormSelected('location-information');
                  }
                }}
              >
                Próxima Etapa
              </button>
              <button
                className="form__button--outline"
                type="button"
                onClick={() => setStepFormSelected('personal-information')}
              >
                Voltar
              </button>
            </>
          ) : null }

        {stepFormSelected === 'location-information'
          ? (
            <>
              <p className="form__description">
                Informações de Localização
              </p>

              <div className="label__container">
                <p className="form__label">
                  Endereço (Rua/Av/Travessa, número) *
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui seu endereço"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />

              <div className="label__container">
                <p className="form__label">
                  Estado *
                </p>
              </div>
              <select
                name="ufs"
                className="form__select"
                value={ufSelected}
                onChange={(event) => {
                  console.log('select | uf | event.target.value: ', event.target.value);
                  setUfSelected(event.target.value);
                  const ufFound = ufsInAlphabeticalOrder.find((uf) => uf.uf === event.target.value);
                  const citiesByUfUpdate = citiesInAlphabeticalOrder.filter(
                    (city) => city.uf_id === ufFound.id,
                  );
                  console.log('onVisibleModelChoices | citiesByUfUpdate: ', citiesByUfUpdate);
                  setCitiesByUf(citiesByUfUpdate);
                  setCitySelected(citiesByUfUpdate[0]);
                }}
              >
                {
                  ufsInAlphabeticalOrder.map((uf) => (
                    <option value={uf.uf} key={uf.uf}>
                      {uf.uf}
                    </option>
                  ))
                }
              </select>

              <div className="label__container">
                <p className="form__label">
                  Cidade *
                </p>
              </div>
              <select
                name="cities"
                className="form__select"
                value={citySelected}
                onChange={(event) => {
                  console.log('select | city | event.target.value: ', event.target.value);
                  setCitySelected(event.target.value);
                }}
              >
                {
                  citiesByUf.map((uf) => (
                    <option value={uf.name} key={uf.name}>
                      {uf.name}
                    </option>
                  ))
                }
              </select>

              <div className="label__container">
                <p className="form__label">
                  Bairro
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui seu bairro"
                value={district}
                onChange={(event) => setDistrict(event.target.value)}
              />

              <div className="label__container">
                <p className="form__label">
                  CEP
                </p>
              </div>
              <input
                className="form__input"
                type="text"
                placeholder="Digite aqui seu CEP"
                value={cep}
                maxLength={9}
                onChange={(event) => changeCep(event.target.value)}
              />

              <button
                className="form__button"
                type="button"
                onClick={() => {
                  if (!address) {
                    setMessageWarning('Preencha o campo endereço');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else {
                    setMessageWarning('');
                    setStepFormSelected('access-information');
                  }
                }}
              >
                Próxima Etapa
              </button>
              <button
                className="form__button--outline"
                type="button"
                onClick={() => setStepFormSelected('contact-information')}
              >
                Voltar
              </button>
            </>
          ) : null }

        {stepFormSelected === 'access-information' && !isSuccessMessage
          ? (
            <>
              <p className="form__description">
                Informações de acesso
              </p>

              <div className="label__container">
                <p className="form__label">
                  Senha *
                </p>
              </div>
              <div className="form__password">
                <input
                  className="input-password"
                  id="passwordIdOne"
                  type={isPasswordSecure ? 'password' : 'text'}
                  placeholder="Digite aqui sua senha"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                {isPasswordSecure
                  ? (
                    <IoEye
                      size={25}
                      color="#456A27"
                      onClick={() => setIsPasswordSecure(!isPasswordSecure)}
                    />
                  ) : (
                    <IoEyeOff
                      size={25}
                      color="#456A27"
                      onClick={() => setIsPasswordSecure(!isPasswordSecure)}
                    />
                  )}
              </div>
              <div className="label__container">
                <p className="form__label">
                  Confirmar Senha *
                </p>
              </div>
              <div className="form__password">
                <input
                  className="input-password"
                  id="passwordIdTwo"
                  type={isPasswordConfirmSecure ? 'password' : 'text'}
                  placeholder="Confirme aqui sua senha"
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
                {isPasswordConfirmSecure
                  ? (
                    <IoEye
                      size={25}
                      color="#456A27"
                      onClick={() => setIsPasswordConfirmSecure(!isPasswordConfirmSecure)}
                    />
                  ) : (
                    <IoEyeOff
                      size={25}
                      color="#456A27"
                      onClick={() => setIsPasswordConfirmSecure(!isPasswordConfirmSecure)}
                    />
                  )}
              </div>

              <div className="form__checkbox">
                <input
                  type="checkbox"
                  id="custom-checkbox"
                  checked={isAcceptedTermsOfUse}
                  onChange={() => setIsAcceptedTermsOfUse(!isAcceptedTermsOfUse)}
                />

                <p className="form__checkbox--text">
                  Li e estou de acordo com os
                  <a
                    className="termos__checkbox"
                    href={urlTermOfUse}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Termos de Uso
                  </a>
                </p>
              </div>

              <button
                className="form__button"
                type="button"
                onClick={(event) => {
                  if (!password) {
                    setMessageWarning('Preencha o campo senha');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (password.length < 4) {
                    setMessageWarning('Campo senha é inválido');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (password.length < 8 && password.length > 15) {
                    setMessageWarning('Campo senha deve conter de 8 à 15 caracteres');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (!confirmPassword) {
                    setMessageWarning('Campo confirmar senha é inválido');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (passwordIsInvalid(confirmPassword)) {
                    setMessageWarning('Campo confirmar senha deve conter números, símbolos, letras maiúsculas e minúsculas');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (confirmPassword.length < 8 && confirmPassword.length > 15) {
                    setMessageWarning('Campo confirmar senha deve conter de 8 à 15 caracteres');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (password !== confirmPassword) {
                    setMessageWarning('Os campos senha e confirmar senha devem ser iguais');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else if (!isAcceptedTermsOfUse) {
                    setMessageWarning('concorde com os termos de uso');
                    setIsSuccessMessage(false);
                    setIsLoad(false);
                  } else {
                    setMessageWarning('');
                    sendSignUp(event);
                  }
                }}
              >
                Cadastrar
              </button>
              <button
                className="form__button--outline"
                type="button"
                onClick={() => setStepFormSelected('location-information')}
              >
                Voltar
              </button>
            </>
          ) : null }
        <p className="redirect__text">
          <Link className="redirect__link" to="/">Voltar ao acesso</Link>
        </p>
      </div>
    </div>
  );
}
