import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export async function PropertiesFetch(tokenAuthorization) {
  console.log('PropertiesFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: 'properties/properties_by_user',
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('PropertiesFetch | response.data.properties: ', response.data.properties);
    return {
      isSuccess: true,
      message: '',
      properties: response.data.properties,
    };
  } catch (error) {
    console.log('PropertiesFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function PropertyByIdFetch(tokenAuthorization, propertyId) {
  console.log('PropertyByIdFetch | tokenAuthorization, propertyId: ', tokenAuthorization, propertyId);
  const configRequest = {
    method: 'get',
    url: `/properties/property_by_id?property_id=${propertyId}`,
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('PropertyByIdFetch | response.data: ', response.data);

    return {
      isSuccess: true,
      message: '',
      property: response.data.property,
    };
  } catch (error) {
    console.log('PropertyByIdFetch | error: ', error.message);
    let message;
    if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
      );
    }

    return {
      isSuccess: false,
      message,
    };
  }
}

export async function PlotsByPropertyIdFetch(tokenAuthorization, propertyId) {
  console.log('PlotsByPropertyIdFetch | tokenAuthorization, propertyId: ', tokenAuthorization, propertyId);
  const configRequest = {
    method: 'get',
    url: `/plots/plots_by_property?protheus_id=${propertyId}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('PlotsByPropertyIdFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      plots: response.data.plots,
    };
  } catch (error) {
    console.log('PlotsByPropertyIdFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      plots: [],
    };
  }
}

export async function PlotByIdFetch(tokenAuthorization, plotId) {
  console.log('PlotByIdFetch | tokenAuthorization, plotId: ', tokenAuthorization, plotId);
  const configRequest = {
    method: 'get',
    url: `/plots/plot_by_id?plot_id=${plotId}`,
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('PlotByIdFetch | response.data: ', response.data);

    return {
      isSuccess: true,
      message: '',
      plot: response.data.plot,
    };
  } catch (error) {
    console.log('PlotByIdFetch | error: ', error.message);
    let message;
    if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
      );
    }

    return {
      isSuccess: false,
      message,
    };
  }
}

export async function PlotsFetch(tokenAuthorization) {
  console.log('PlotsFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/plots/plots_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('PlotsFetch | response.data.plots: ', response.data.plots);
    return {
      isSuccess: true,
      message: '',
      plots: response.data.plots,
    };
  } catch (error) {
    console.log('PlotsFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}
