/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarUSAMask from '../../utils/masks/calendarUSAMask';
import weightMask from '../../utils/masks/weightMask';

import { OrganicChestnutByIdFetch } from '../../api/services/NotebookAPI';

export default function OrganicChestnutDetail({ history }) {
  const token = getToken();
  const [organicChestnut, setOrganicChestnut] = useState(null);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { organicChestnutId } = useParams();

  async function getClaimById() {
    setIsLoad(true);
    try {
      const resultOrganicChestnutById = await OrganicChestnutByIdFetch(token, organicChestnutId);
      console.log('getOrganicChestnutById | resultOrganicChestnutById: ', resultOrganicChestnutById);
      setIsLoad(false);
      setMessageWarning(resultOrganicChestnutById.message);
      setIsSuccessMessage(resultOrganicChestnutById.isSuccess);
      setOrganicChestnut(resultOrganicChestnutById.organicChestnutDetail);
    } catch (error) {
      console.log('getOrganicChestnutById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getClaimById();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da Castanha Orgânica
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {organicChestnut !== null
            ? (
              <div className="detail__container">
                <p className="detail__label">
                  Marca do Veículo Transportado
                </p>
                <p className="detail__value">
                  {organicChestnut.vehicle_brand}
                </p>

                <p className="detail__label">
                  Modelo do Veículo Transportador
                </p>
                <p className="detail__value">
                  {organicChestnut.vehicle_model}
                </p>

                <p className="detail__label">
                  N° da Placa do Veículo Transportador
                </p>
                <p className="detail__value">
                  {organicChestnut.vehicle_license_plate}
                </p>

                <p className="detail__label">
                  Propriedade
                </p>
                <p className="detail__value">
                  {organicChestnut.property.name}
                </p>

                <p className="detail__label">
                  Código do Projeto Orgânico
                </p>
                <p className="detail__value">
                  {organicChestnut.organic_project_code}
                </p>

                <p className="detail__label">
                  Cidade
                </p>
                <p className="detail__value">
                  {organicChestnut.city}
                </p>

                <p className="detail__label">
                  Peso
                </p>
                <p className="detail__value">
                  {weightMask(organicChestnut.weight)}
                </p>

                <p className="detail__label">
                  Nome do Fiscal
                </p>
                <p className="detail__value">
                  {organicChestnut.observation}
                </p>

                <p className="detail__label">
                  Criado Em
                </p>
                <p className="detail__value">
                  {calendarUSAMask(organicChestnut.created)}
                </p>
              </div>
            ) : null }
        </div>
      </div>
    </>
  );
}
