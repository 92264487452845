/* eslint-disable radix */
export default function censorshipMask(text = '', percentage = 100) {
  // console.log('censorshipMask | text.length: ', text.length);
  const censorUpToIndex = parseInt((text.length * percentage) / 100);
  // console.log('censorshipMask | censorUpToIndex: ', censorUpToIndex);

  let uncensoredPart = text.substring(censorUpToIndex, text.length);
  // console.log('censorshipMask | uncensoredPart: ', uncensoredPart);

  let loop = 0;

  while (loop < censorUpToIndex) {
    loop += 1;
    // console.log('censorshipMask | while | loop: ', loop);
    uncensoredPart = ('*').concat(uncensoredPart);
    // console.log('censorshipMask | while | uncensoredPart: ', uncensoredPart);
  }

  return uncensoredPart;
}
