/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import date from 'date-and-time';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import Load from '../../components/Load';
import NavBar from '../../components/NavBar';

import { getToken } from '../../api/auth';
import dateTimeMask from '../../utils/masks/dateTimeMask';
import removeLetter from '../../utils/masks/removeLetter';
import removeSpecial from '../../utils/masks/removeSpecial';

import { FertilizingCreateFetch } from '../../api/services/NotebookAPI';

export default function FertilizingCreate({ history }) {
  const token = getToken();

  const [stackNumber, setStackNumber] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [dateMounting, setDateMounting] = useState('');
  const [dateFinishing, setDateFinishing] = useState('');
  const [twistTemperature, setTwistTemperature] = useState('');
  const [twistTemperature2, setTwistTemperature2] = useState('');
  const [twistTemperature3, setTwistTemperature3] = useState('');
  const [twistTemperature4, setTwistTemperature4] = useState('');
  const [twistTemperature5, setTwistTemperature5] = useState('');
  const [observation, setObservation] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  async function sendFertilizingCreate(event) {
    event.preventDefault();
    try {
      setIsLoad(true);
      const isValidDateMounting = date.isValid(dateMounting, 'DD/MM/YYYY');
      const isValidDateFinishing = date.isValid(dateFinishing, 'DD/MM/YYYY');

      if (!stackNumber) {
        setMessageWarning('preencha o campo nº da pilha');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!ingredients) {
        setMessageWarning('preencha o campo ingredientes da pilha');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!isValidDateMounting && dateMounting.length < 10) {
        setMessageWarning('campo data da montagem é inválido');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!twistTemperature) {
        setMessageWarning('preencha o campo revirada e temperatura');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!twistTemperature2) {
        setMessageWarning('preencha o campo revirada e temperatura 2');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!twistTemperature3) {
        setMessageWarning('preencha o campo revirada e temperatura 3');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!twistTemperature4) {
        setMessageWarning('preencha o campo revirada e temperatura 4');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!twistTemperature5) {
        setMessageWarning('preencha o campo revirada e temperatura 5');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else if (!isValidDateFinishing && dateFinishing.length < 10) {
        setMessageWarning('campo data de finalização da pilha é inválido');
        setIsSuccessMessage(false);
        setIsLoad(false);
      } else {
        const resultFertilizingCreate = await FertilizingCreateFetch(
          token,
          stackNumber,
          ingredients,
          dateMounting,
          dateFinishing,
          twistTemperature,
          twistTemperature2,
          twistTemperature3,
          twistTemperature4,
          twistTemperature5,
          observation,
        );
        console.log('sendFertilizingCreate | resultFertilizingCreate', resultFertilizingCreate);
        setIsLoad(false);
        setIsSuccessMessage(resultFertilizingCreate.isSuccess);
        setMessageWarning(resultFertilizingCreate.message);
      }
    } catch (error) {
      console.log('sendFertilizingCreate | error', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  function changeDateTimeMaskInput(textValue) {
    console.log('changePhone | textValue: ', textValue);
    const texDateTime = dateTimeMask(textValue);
    console.log('onChangeText | texDateTime: ', texDateTime);
    return texDateTime;
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Registrar Compostagem
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {!isSuccessMessage
            ? (
              <>
                <div className="label__container">
                  <p className="form__label">
                    N° da Pilha *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o nº da pilha"
                  value={stackNumber}
                  onChange={(event) => {
                    const accountNumberWithoutLetter = removeLetter(event.target.value);
                    console.log('onChange | accountNumber | accountNumberWithoutLetter', accountNumberWithoutLetter);
                    const accountNumberWithoutSpecial = removeSpecial(accountNumberWithoutLetter);
                    console.log('onChange | accountNumber | accountNumberWithoutSpecial', accountNumberWithoutSpecial);
                    setStackNumber(accountNumberWithoutSpecial);
                  }}
                />

                <div className="label__container">
                  <p className="form__label">
                    Ingredientes da Pilha *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui os ingredientes da pilha"
                  value={ingredients}
                  onChange={(event) => setIngredients(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Data da Montagem *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui sua data da montagem"
                  value={dateMounting}
                  maxLength={10}
                  onChange={(event) => {
                    const dateMountingWithMask = changeDateTimeMaskInput(event.target.value);
                    setDateMounting(dateMountingWithMask);
                  }}
                />

                <div className="label__container">
                  <p className="form__label">
                    Revirada e Temperatura (°C) *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a revirada e temperatura"
                  value={twistTemperature}
                  onChange={(event) => setTwistTemperature(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Revirada e Temperatura 2 (°C) *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a revirada e temperatura 2"
                  value={twistTemperature2}
                  onChange={(event) => setTwistTemperature2(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Revirada e Temperatura 3 (°C) *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a revirada e temperatura 3"
                  value={twistTemperature3}
                  onChange={(event) => setTwistTemperature3(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Revirada e Temperatura 4 (°C) *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a revirada e temperatura 4"
                  value={twistTemperature4}
                  onChange={(event) => setTwistTemperature4(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Revirada e Temperatura 5 (°C) *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a revirada e temperatura 5"
                  value={twistTemperature5}
                  onChange={(event) => setTwistTemperature5(event.target.value)}
                />

                <div className="label__container">
                  <p className="form__label">
                    Data de Finalização da Pilha *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui sua data de finalização da pilha"
                  value={dateFinishing}
                  maxLength={10}
                  onChange={(event) => {
                    const dateFinishingWithMask = changeDateTimeMaskInput(event.target.value);
                    setDateFinishing(dateFinishingWithMask);
                  }}
                />

                <div className="label__container">
                  <p className="form__label">
                    Observação
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui a observação"
                  value={observation}
                  onChange={(event) => setObservation(event.target.value)}
                />

                <button
                  className="form__button"
                  type="button"
                  onClick={(event) => sendFertilizingCreate(event)}
                >
                  Resgistrar
                </button>
              </>
            ) : null }

          {isSuccessMessage && messageWarning
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => navigate('/notebook/fertilizing', { replace: true })}
              >
                Ir para compostagens
              </button>
            ) : null }
        </div>
      </div>
    </>
  );
}
