/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarWithHourAndMinutesMask from '../../utils/masks/calendarWithHourAndMinutesMask';
import { addMoneyMask } from '../../utils/masks/manipulateMoneyMask';

import {
  DemandByIdFetch,
  StagesByDemandIdFetch,
  RatingByDemandFetch,
} from '../../api/services/StoreAPI';

export default function DemandDetail({ history }) {
  const token = getToken();

  const [demand, setDemand] = useState(null);
  const [stages, setStages] = useState([]);
  const [ratingDetail, setRatingDetail] = useState(null);
  const [isAlreadyRating, setIsAlreadyRating] = useState(false);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { demandId } = useParams();
  const navigate = useNavigate();

  async function getDemandById() {
    setIsLoad(true);
    try {
      const resultDemandById = await DemandByIdFetch(token, demandId);
      console.log('getDemandById | resultDemandById: ', resultDemandById);
      setIsLoad(false);
      setMessageWarning(resultDemandById.message);
      setIsSuccessMessage(resultDemandById.isSuccess);
      setDemand(resultDemandById.demand);
    } catch (error) {
      console.log('getDemandById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getRatingByDemand() {
    setIsLoad(true);
    try {
      const resultRatingByDemand = await RatingByDemandFetch(token, demandId);
      console.log('getRatingByDemand | resultRatingByDemand: ', resultRatingByDemand);
      setIsLoad(false);
      setMessageWarning(resultRatingByDemand.message);
      setIsSuccessMessage(resultRatingByDemand.isSuccess);
      setRatingDetail(resultRatingByDemand.rating);
      setIsAlreadyRating(resultRatingByDemand.isAlreadyRating);
    } catch (error) {
      console.log('getRatingByDemand | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function getStagesByDemandIdFetch() {
    setIsLoad(true);
    try {
      const resultStages = await StagesByDemandIdFetch(token, demandId);
      console.log('getStagesByDemandIdFetch | resultStages: ', resultStages);
      setIsLoad(false);
      setMessageWarning(resultStages.message);
      setIsSuccessMessage(resultStages.isSuccess);
      if (resultStages.isSuccess) {
        setStages(resultStages.stages);
      }
    } catch (error) {
      console.log('getStagesByDemandIdFetch | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getDemandById();
    getStagesByDemandIdFetch();
    getRatingByDemand();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes do Pedido
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {demand !== null
            ? (
              <>
                {isAlreadyRating && ratingDetail !== null
                  ? (
                    <div className="detail__container">
                      <p className="detail__label">
                        NOTA DA AVALIAÇÃO
                      </p>
                      <p className="detail__value">
                        {ratingDetail.evaluation}
                      </p>

                      {ratingDetail.observation !== ''
                        ? (
                          <>
                            <p className="detail__label">
                              OBSERVAÇÃO
                            </p>
                            <p className="detail__value">
                              {ratingDetail.observation}
                            </p>
                          </>
                        ) : null}
                    </div>
                  ) : null }

                {demand.order_concluded && !isAlreadyRating
                  ? (
                    <button
                      className="form__button"
                      type="button"
                      onClick={() => navigate(`/rating/${demandId}`, { replace: true })}
                    >
                      Avaliar Pedido
                    </button>
                  ) : null }

                <div className="detail__container">
                  <p className="detail__label">
                    SITUAÇÃO
                  </p>
                  <p className="detail__value">
                    {demand.status}
                  </p>

                  <p className="detail__label">
                    PEDIDO FOI ENTREGUE?
                  </p>
                  <p className="detail__value">
                    {demand.order_concluded ? 'sim' : 'não'}
                  </p>

                  <p className="detail__label">
                    ITEM DO PEDIDO
                  </p>
                  <p className="detail__value">
                    {demand.item.name}
                  </p>

                  <p className="detail__label">
                    TIPO DE ITEM
                  </p>
                  <p className="detail__value">
                    {demand.item.type}
                  </p>

                  <p className="detail__label">
                    DESCRIÇÃO DO ITEM
                  </p>
                  <p className="detail__value">
                    {demand.item.description}
                  </p>

                  <p className="detail__label">
                    QUANTIDADE
                  </p>
                  <p className="detail__value">
                    {demand.quantity}
                  </p>

                  <p className="detail__label">
                    VALOR TOTAL
                  </p>
                  <p className="detail__value">
                    {addMoneyMask(demand.value)}
                  </p>

                  <p className="detail__label">
                    FORMA DE PAGAMENTO
                  </p>
                  <p className="detail__value">
                    {demand.payment_type.name}
                  </p>

                  <p className="detail__label">
                    PAGAMENTO FOI CONFIRMADO ?
                  </p>
                  <p className="detail__value">
                    {demand.is_paid ? 'sim' : 'não'}
                  </p>

                  {demand.observation !== ''
                    ? (
                      <>
                        <p className="detail__label">
                          OBSERVAÇÃO
                        </p>
                        <p className="detail__value">
                          {demand.observation}
                        </p>
                      </>
                    ) : null }

                  <p className="detail__label">
                    CRIADO EM
                  </p>
                  <p className="detail__value">
                    {calendarWithHourAndMinutesMask(demand.created)}
                  </p>
                </div>

                {demand.discount !== null
                  ? (
                    <div className="detail__container">
                      <p className="detail__label">
                        NOME DO CUPOM
                      </p>
                      <p className="detail__value">
                        {demand.discount.name}
                      </p>

                      <p className="detail__label">
                        DESCRIÇÃO
                      </p>
                      <p className="detail__value">
                        {demand.discount.description}
                      </p>

                      <p className="detail__label">
                        PORCENTAGEM DE DESCONTO
                      </p>
                      <p className="detail__value">
                        {demand.discount.percent}
                      </p>
                    </div>
                  ) : null}
              </>
            ) : null }

          <p className="form__description">
            Etapas
          </p>

          {stages.length === 0
            ? (
              <p className="form__message">Não há etapa relacionada ao seu pedido.</p>
            ) : null }

          {stages.map((action) => (
            <div className="detail__container">
              <p className="detail__label">
                Descrição
              </p>
              <p className="detail__value">
                {action.description}
              </p>

              <p className="detail__label">
                Realizado Em
              </p>
              <p className="detail__value">
                {calendarWithHourAndMinutesMask(action.created)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
