/* eslint-disable no-multiple-empty-lines */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';

export default function Notebook() {
  const navigate = useNavigate();
  return (
    <>
      <NavBar />
     <div className='container__above_header'>

     
      <div className="form">
        <p className="form__description">
          Caderno do Campo
        </p>
        <button
          type="button"
          className="form__button"
          onClick={() => navigate('/notebook/field-activity')}
        >
         <p className="detail-value"> Controle de atividades de campos</p>

        </button>
        <button
          className="form__button"
          type="button"
          onClick={() => navigate('/notebook/crop')}
        >
          Controle de colheita
        </button>

        <button
          className="form__button"
          type="button"
          onClick={() => navigate('/notebook/application')}
        >
          Controle de aplicações e pulverizações
        </button>

        <button
          className="form__button"
          type="button"
          onClick={() => navigate('/notebook/cleaning')}
        >
          Controle de limpeza de utensílios, equipamentos e instalações
        </button>

        <button
          className="form__button"
          type="button"
          onClick={() => navigate('/notebook/fertilizing')}
        >
          Monitoramento de compostagem
        </button>
        <button
          className="form__button"
          type="button"
          onClick={() => navigate('/notebook/purchase')}
        >
          Tabela de compras
        </button>
        <button
          className="form__button"
          type="button"
          onClick={() => navigate('/notebook/organic-chestnut')}
        >
          Declaração de envio da castanha ôrganica
        </button>
        <button
          className="form__button"
          type="button"
          onClick={() => navigate('/notebook/claim')}
        >
          Registrar reclamação
        </button>

        </div>

      </div>

    </>
  );
}
