/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarUSAMask from '../../utils/masks/calendarUSAMask';

import { PurchaseByIdFetch } from '../../api/services/NotebookAPI';

export default function PurchaseDetail({ history }) {
  const token = getToken();
  const [purchase, setPurchase] = useState(null);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const { purchaseId } = useParams();

  async function getPurchaseById() {
    setIsLoad(true);
    try {
      const resultPurchaseById = await PurchaseByIdFetch(token, purchaseId);
      console.log('getPurchaseById | resultPurchaseById: ', resultPurchaseById);
      setIsLoad(false);
      setMessageWarning(resultPurchaseById.message);
      setIsSuccessMessage(resultPurchaseById.isSuccess);
      setPurchase(resultPurchaseById.purchaseDetail);
    } catch (error) {
      console.log('getPurchaseById | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getPurchaseById();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Detalhes da Compra
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {purchase !== null
            ? (
              <div className="detail__container">
                <p className="detail__label">
                  Nota Fiscal da Compra / Recibo
                </p>
                <p className="detail__value">
                  {purchase.invoice}
                </p>

                <p className="detail__label">
                  Produto Adquirido
                </p>
                <p className="detail__value">
                  {purchase.product}
                </p>

                <p className="detail__label">
                  Fornecedor
                </p>
                <p className="detail__value">
                  {purchase.provider}
                </p>

                <p className="detail__label">
                  Criado Em
                </p>
                <p className="detail__value">
                  {calendarUSAMask(purchase.created)}
                </p>
              </div>
            ) : null }
        </div>
      </div>
    </>
  );
}
