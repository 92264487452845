import createExceptionSentry from '../../utils/createExceptionSentry';

import Axios from '../Axios';

export default async function TermOfUseFetch() {
  console.log('TermOfUseFetch');

  const configRequest = {
    method: 'get',
    url: '/term_of_use/get_term_of_use',
  };

  try {
    const response = await Axios[configRequest.method](configRequest.url);
    console.log('TermOfUseFetch | response.data ', response.data);

    return {
      isSuccess: true,
      message: '',
      termOfUse: response.data.terms.terms_of_use,
    };
  } catch (error) {
    console.log('TermOfUseFetch | error: ', error.message);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      termOfUse: '',
    };
  }
}
