/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';

import { getToken } from '../../api/auth';
import calendarUSAMask from '../../utils/masks/calendarUSAMask';

import { ApplicationsFetch } from '../../api/services/NotebookAPI';

export default function Application({ history }) {
  const token = getToken();
  const [applications, setApplications] = useState([]);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function getApplications() {
      setIsLoad(true);
      try {
        const resultApplications = await ApplicationsFetch(token);
        console.log('getApplications | resultApplications: ', resultApplications);
        setIsLoad(false);
        setMessageWarning(resultApplications.message);
        setIsSuccessMessage(resultApplications.isSuccess);
        if (resultApplications.isSuccess) {
          setApplications(resultApplications.applications);
        }
      } catch (error) {
        console.log('getApplications | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getApplications();
  }, [history, token]);

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Histórico de Aplicações
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          <button
            className="form__button"
            type="button"
            // eslint-disable-next-line no-undef
            onClick={() => navigate('/notebook/application/create', { replace: true })}
          >
            Registrar Aplicação
          </button>

          {applications.map((application) => (
            <div
              className="detail__container"
              onClick={() => navigate(`/notebook/application/${application.id}`, { replace: true })}
              aria-hidden="true"
            >
              <p className="detail__value">
                {(application.input).toUpperCase()}
              </p>
              <p className="detail__value">
                {calendarUSAMask(application.created)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
