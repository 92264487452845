/* eslint-disable no-console */
import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export async function FinancialsFetch(tokenAuthorization) {
  console.log('FinancialsFetch | tokenAuthorization:', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/loans/loans_by_user',
    headers: { headers: { Authorization: tokenAuthorization } },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('FinancialsFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: '',
      loans: response.data.loans,
    };
  } catch (error) {
    console.log('FinancialsFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
      configRequest.headers.headers,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
      loans: [],
    };
  }
}

export async function FinancialsCreateFetch(tokenAuthorization, loanValue) {
  console.log(
    'FinancialsCreateFetch | tokenAuthorization, loanValue: ',
    tokenAuthorization,
    loanValue,
  );
  const configRequest = {
    method: 'post',
    url: '/loans/create_loan',
    headers: {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: tokenAuthorization,
      },
    },
  };

  const body = new FormData();

  body.append('value', loanValue);

  console.log('FinancialsCreateFetch | body:', body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      body,
      configRequest.headers,
    );

    console.log('FinancialsCreateFetch | response.data: ', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('FinancialsCreateFetch | error: ', error);
    let message;
    if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        {
          ...configRequest.headers.headers,
          ...configRequest.body,
        },
      );

      return {
        isSuccess: false,
        message,
        financials: null,
      };
    }
  }
}
