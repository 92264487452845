/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import './styles.css';

import NavBar from '../../components/NavBar';
import { LevelsFetch, LevelUserFetch } from '../../api/services/LevelsAPI';
import ModalNextLevel from './components/ModalNextLevel';
import { BenefitsTable } from './components/BenefitsTable';
import Load from '../../components/Load';
import { getToken } from '../../api/auth';

export default function Fanducaju({ history }) {
  const token = getToken();
  const [levels, setLevels] = useState([]);
  const [levelUser, setLevelUser] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  async function getLevels() {
    setIsLoad(true);
    try {
      const resultLevels = await LevelsFetch();
      console.log('getLevels | resultLevels: ', resultLevels);
      setIsLoad(false);
      setMessageWarning(resultLevels.message);
      setIsSuccessMessage(resultLevels.isSuccess);
      if (resultLevels.isSuccess) {
        setLevels(resultLevels.levels);
      }
    } catch (error) {
      console.log('getLevels | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }
  function benefitsSplit(benefits) {
    console.log('benefitsSplit | benefits: ', benefits);
    try {
      const regex = /\s*;\s*/;
      const benefitsList = benefits.split(regex);
      console.log('benefitsSplit | benefitsList: ', benefitsList);
      return benefitsList;
    } catch (error) {
      console.log('benefitsSplit | error: ', error);
      return [];
    }
  }

  async function getLevelUser() {
    setIsLoad(true);
    try {
      const resultLevelFromUser = await LevelUserFetch(token);
      console.log('getLevelUser | resultLevelFromUser ', resultLevelFromUser);
      setIsLoad(false);
      setMessageWarning(resultLevelFromUser.message);
      if (resultLevelFromUser.isSuccess) {
        setLevelUser(resultLevelFromUser.level);
      }
    } catch (error) {
      console.log('getLevelUser | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  useEffect(() => {
    getLevelUser();
    getLevels();
  }, [history, token]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Programa FANDUCAJU
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {modalVisible ? (
            <ModalNextLevel
              onClose={() => setModalVisible(false)}
              levelParam={levels[levelUser.position].name}
              medalIcon={levels[levelUser.position].image}
              requirementsParam={levels[levelUser.position].requirements}
            />
          )
            : null }

          {levelUser === null
            ? (
              <p className="form__message">Nenhum nível foi atribuido.</p>
            ) : null }

          {levelUser !== null ? (
            <>
              <div className="medal__container" aria-hidden="true">
                <p className="titleCardMedal__value">
                  Classificação do produtor
                </p>
                <img src={levelUser.image} alt="newsImage" width="100%" height="180" style={{ objectFit: 'contain' }} />
                <p className="descriptionCardLevel__value">
                  Você está alocado na classificação
                  {' '}
                  {(levelUser.name)}
                </p>
              </div>
              <p className="benefitsCardLevel__value">
                Benefícios
              </p>

              <BenefitsTable benefitsParam={benefitsSplit(levelUser.benefits)} />

              <p className="benefitsInfo">As máquinas serão direcionadas de acordo com o volume e localidades (logística).</p>
              <p className="benefitsInfo">Os insumos terão como referência o volume de castanha entregue à Usibras.</p>

              {levels.length !== levelUser.position ? (
                <button
                  className="form__button"
                  type="button"
                  // eslint-disable-next-line no-undef
                  onClick={() => setModalVisible(true)}
                >
                  Aumentar nível
                </button>
              ) : null }
            </>
          ) : null }
        </div>
      </div>
    </>
  );
}
