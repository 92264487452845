/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  IoRadioButtonOff,
  IoRadioButtonOn,
} from 'react-icons/io5';

import './styles.css';

export default function SlideButtons({ newsParam, onAction }) {
  function onSelectedBanner(
    newsLoopIndexSelected,
    newsListSelected,
  ) {
    const newsLoopWithHidden = newsListSelected.map((
      newsCurrent,
      newsCurrentIndex,
    ) => {
      const newsLoopManipule = newsCurrent;
      newsLoopManipule.isHidden = (newsLoopIndexSelected !== newsCurrentIndex);
      console.log('onSelectedBanner | newsLoopManipule: ', newsLoopManipule);
      return newsLoopManipule;
    });
    console.log('onSelectedBanner | newsLoopWithHidden: ', newsLoopWithHidden);
    onAction(newsLoopWithHidden);
  }

  function renderButtonsSlide(
    newsLoopToRender,
    newsLoopIndexToRender,
    newsListToRender,
  ) {
    if (newsLoopToRender.isHidden) {
      return (
        <IoRadioButtonOff
          size={20}
          color="#456A27"
          onClick={() => onSelectedBanner(
            newsLoopIndexToRender,
            newsListToRender,
          )}
        />
      );
    }
    return (
      <IoRadioButtonOn
        size={20}
        color="#456A27"
        onClick={() => onSelectedBanner(
          newsLoopIndexToRender,
          newsListToRender,
        )}
      />
    );
  }

  return (
    <div className="slideshow_buttons">
      {newsParam.map((newsLoop, newsLoopIndex, newsList) => renderButtonsSlide(
        newsLoop,
        newsLoopIndex,
        newsList,
      ))}
    </div>
  );
}
