/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';

import './styles.css';

export default function SummaryNotFound({ label, onAction }) {
  return (
    <div
      className="summaryNotFound"
      onClick={() => onAction()}
      aria-hidden="true"
    >
      <p className="summaryNotFound__value">
        {label}
      </p>
    </div>
  );
}
