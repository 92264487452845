/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

export default function QualityList({ qualityParam, onAction }) {
  return (
    <div
      className="detail__container"
      onClick={() => onAction()}
      aria-hidden="true"
    >
      <p className="detail__value">
        {qualityParam.dataDoLaudo}
      </p>
      <p className="detail__value">
        {qualityParam.fornecLaudo}
      </p>
      <p className="detail__value">
        {qualityParam.municipioLaudo}
      </p>
    </div>
  );
}
