import cpfMask from './cpfMask';
import cnpjMask from './cnpjMask';

export function addCpfCnpjMask(text) {
  // console.log('cpfCnpjMask | text.length: ', text.length);
  const formatMask = text.length > 11 ? cnpjMask(text) : cpfMask(text);
  // console.log('cpfCnpjMask | formatMask: ', formatMask);
  return formatMask;
}

export function removeCpfOrCnpjMask(value) {
  return value
    .replace('.', '')
    .replace('.', '')
    .replace('.', '')
    .replace('-', '')
    .replace('/', '');
}
