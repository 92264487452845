/* eslint-disable no-console */
import FormData from 'form-data';

import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export async function SolicitationsFetch(tokenAuthorization) {
  console.log('SolicitationsFetch | tokenAuthorization: ', tokenAuthorization);
  const configRequest = {
    method: 'get',
    url: '/solicitations/read_solicitations',
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );
    console.log('SolicitationsFetch | response.data.solicitations: ', response.data.solicitations);
    return {
      isSuccess: true,
      message: '',
      solicitations: response.data.solicitations,
    };
  } catch (error) {
    console.log('SolicitationsFetch | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}

export async function SolicitationByIdFetch(tokenAuthorization, solicitationId) {
  console.log('SolicitationByIdFetch | tokenAuthorization, solicitationId: ', tokenAuthorization, solicitationId);
  const configRequest = {
    method: 'get',
    url: `/solicitations/solicitation_by_id?solicitation_id=${solicitationId}`,
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('SolicitationByIdFetch | response.data.solicitation: ', response.data.solicitation);
    return {
      isSuccess: true,
      message: '',
      solicitation: response.data.solicitation,
    };
  } catch (error) {
    console.log('SolicitationByIdFetch | error: ', error);
    let message;
    if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
      );
    }

    return {
      isSuccess: false,
      message,
    };
  }
}

export async function SolicitationByIdUpdateFetch(
  tokenAuthorization,
  solicitationId,
  solicitation,
) {
  console.log(
    'SolicitationByIdUpdateFetch | tokenAuthorization, solicitationId, solicitation: ',
    tokenAuthorization,
    solicitationId,
    solicitation,
  );
  const configRequest = {
    method: 'put',
    url: `/solicitations/update_solicitation_by_id?solicitation_id=${solicitationId}`,
    body: solicitation,
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.body,
      configRequest.headers,
    );
    console.log('SolicitationByIdUpdateFetch | response.data: ', response.data);

    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('SolicitationByIdUpdateFetch | error: ', error);
    createExceptionSentry(error, configRequest.method, configRequest.url);
    let message;
    if (error.message === 'Request failed with status code 404') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
      );
    }

    return {
      isSuccess: false,
      message,
    };
  }
}

export async function SolicitationCreateFetch(
  tokenAuthorization,
  categoryId,
  imageUri,
  description,
  audioUri,
) {
  console.log(
    'SolicitationCreateFetch | tokenAuthorization, categoryId, imageUri, description, audioUri: ',
    tokenAuthorization,
    categoryId,
    imageUri,
    description,
    audioUri,
  );
  const configRequest = {
    method: 'post',
    url: '/solicitations/create_solicitation',
    headers: {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: tokenAuthorization,
      },
    },
  };

  const body = new FormData();

  body.append('category', categoryId);

  if (audioUri) {
    body.append('audio', audioUri);
  }
  if (description) {
    body.append('description', description);
  }
  if (imageUri) {
    body.append('image', imageUri);
  }
  console.log('SolicitationCreateFetch | body:', body);

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      body,
      configRequest.headers,
    );
    console.log('SolicitationCreateFetch | response.data:', response.data);
    return {
      isSuccess: true,
      message: response.data.message,
    };
  } catch (error) {
    console.log('SolicitationCreateFetch | error:', error.message);
    let message;
    if (error.message === 'Request failed with status code 401') {
      message = error.response.data.message;
    } else {
      message = 'No momento esse recurso está indisponível, tente novamente mais tarde.';
      createExceptionSentry(
        error,
        configRequest.method,
        configRequest.url,
        {
          ...configRequest.headers.headers,
          ...configRequest.body,
        },
      );

      return {
        isSuccess: false,
        message,
      };
    }
  }
}
