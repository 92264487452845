/* eslint-disable no-console */

import createExceptionSentry from '../../utils/createExceptionSentry';
import Axios from '../Axios';

export default async function ActionsBySolicitation(
  tokenAuthorization,
  solicitationId,
) {
  console.log('ActionsBySolicitation | tokenAuthorization, solicitationId: ', tokenAuthorization, solicitationId);
  const configRequest = {
    method: 'get',
    url: `/actions/actions_by_solicitation?solicitation_id=${solicitationId}`,
    headers: {
      headers: { Authorization: tokenAuthorization },
    },
  };

  try {
    const response = await Axios[configRequest.method](
      configRequest.url,
      configRequest.headers,
    );

    console.log('ActionsBySolicitation | response.data.actions: ', response.data.actions);
    return {
      isSuccess: true,
      message: response.data.message,
      actions: response.data.actions,
    };
  } catch (error) {
    console.log('ActionsBySolicitation | error: ', error.message);
    createExceptionSentry(
      error,
      configRequest.method,
      configRequest.url,
    );
    return {
      isSuccess: false,
      message: 'No momento esse recurso está indisponível, tente novamente mais tarde.',
    };
  }
}
