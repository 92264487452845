/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MicRecorder from 'mic-recorder-to-mp3';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import { getToken } from '../../api/auth';
import CategoriesFetch from '../../api/services/CategoryAPI';
import { SolicitationCreateFetch } from '../../api/services/SolicitationAPI';

export default function SolicitationCreate({ history }) {
  const token = getToken();
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState(null);
  const [imageUri, setImageUri] = useState('');
  const [description, setDescription] = useState('');
  const [stepFormSelected, setStepFormSelected] = useState('category-information');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [audioUri, setAudioUri] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [recordingResponse, setRecordingResponse] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function getCategories() {
      setIsLoad(true);
      try {
        const resultCategories = await CategoriesFetch();
        console.log('getCategories | resultCategories: ', resultCategories);
        setIsLoad(false);
        setMessageWarning(resultCategories.message);
        if (resultCategories.isSuccess) {
          setCategories(resultCategories.categories);
        }
      } catch (error) {
        console.log('getCategories | error: ', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }

    getCategories();
  }, [history, token]);

  async function sendSolicitationCreate(event) {
    event.preventDefault();
    setIsLoad(true);
    try {
      const resultSolicitationCreate = await SolicitationCreateFetch(
        token,
        categorySelected.id,
        imageUri,
        description,
        audioUri,
      );
      console.log('sendSolicitationCreate | resultSolicitationCreate: ', resultSolicitationCreate);
      setIsLoad(false);
      setIsSuccessMessage(resultSolicitationCreate.isSuccess);
      setMessageWarning(resultSolicitationCreate.message);
    } catch (error) {
      console.log('sendSolicitationCreate | error: ', error);
      setIsSuccessMessage(false);
      setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
      setIsLoad(false);
    }
  }

  async function onStartRecord() {
    console.log('onStartRecord');
    try {
      console.log('Requesting permissions..');
      const recorder = new MicRecorder({
        bitRate: 128,
      });
      console.log('onStartRecord | recorder: ', recorder);
      // Start recording. Browser will request permission to use your microphone.
      const recording = await recorder.start();
      console.log('onStartRecord | recording: ', recording);
      setRecordingResponse(recorder);
      setIsRecording(true);
      console.log('Recording started');
    } catch (err) {
      console.error('Failed to start recording', err);
    }
  }

  async function onPauseRecord() {
    console.log('pauseRecord | recordingResponse: ', recordingResponse);
    try {
      console.log('Stopping recording..');
      const [buffer, blob] = await recordingResponse.stop().getMp3();
      console.log('pauseRecord | buffer, blob: ', buffer, blob);
      // .then(([buffer, blob]) => {
      //   // do what ever you want with buffer and blob
      //   // Example: Create a mp3 file and play
      const audioFile = new File(buffer, 'me-at-thevoice.mp3', {
        type: blob.type,
        lastModified: Date.now(),
      });
      console.log('pauseRecord | audioFile: ', audioFile);
      const player = new Audio(URL.createObjectURL(audioFile));
      console.log('pauseRecord | player: ', player);
      console.log('pauseRecord | player.src: ', player.src);
      //   player.play();

      // });
      setAudioUri(audioFile);
      setRecordingResponse(null);
      setIsRecording(false);
    } catch (error) {
      console.log('pauseRecord | error: ', error);
    }
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Agendar Solicitação
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {stepFormSelected === 'category-information'
            ? (
              <>
                <p className="form__description">
                  Selecione a categoria de solicitação que deseja agendar
                </p>

                {categories.map((category) => (
                  <button
                    className="form__button"
                    type="button"
                    onClick={() => {
                      setStepFormSelected('image-information');
                      setCategorySelected(category);
                    }}
                  >
                    {category.name}
                  </button>
                ))}

                <button
                  className="form__button--outline"
                  type="button"
                  onClick={() => navigate('/solicitations', { replace: true })}
                >
                  Voltar para solicitações
                </button>
              </>
            ) : null }

          {stepFormSelected === 'image-information'
            ? (
              <>
                <p className="form__description">
                  Insira uma imagem em sua consulta técnica se desejar
                </p>

                <input
                  type="file"
                  name="image"
                  accept="image/png, image/jpeg"
                  className="form__input"
                  placeholder="Inserir magem de sua galeria"
                  value={imageUri}
                  onChange={(event) => {
                    console.log('input file | event: ', event);
                    console.log('input file | event.target.value: ', event.target.value);
                    console.log('input file | event.target.value: ', event.target.files[0]);
                    setImageUri(event.target.files[0]);
                    setStepFormSelected('options-description');
                  }}
                />

                <button
                  className="form__button"
                  type="button"
                  onClick={() => setStepFormSelected('options-description')}
                >
                  Continuar sem Imagem
                </button>

                <button
                  className="form__button--outline"
                  type="button"
                  onClick={() => setStepFormSelected('category-information')}
                >
                  Voltar
                </button>
              </>
            ) : null }

          {stepFormSelected === 'options-description'
            ? (
              <>
                <p className="form__description">
                  Selecione como deseja descrever sua solicitação
                </p>

                <button
                  className="form__button"
                  type="button"
                  onClick={() => setStepFormSelected('description-text')}
                >
                  Descrição em Texto
                </button>

                <button
                  className="form__button"
                  type="button"
                  onClick={() => setStepFormSelected('description-audio')}
                >
                  Descrição em Áudio
                </button>

                <button
                  className="form__button--outline"
                  type="button"
                  onClick={() => setStepFormSelected('image-information')}
                >
                  Voltar
                </button>
              </>
            ) : null }

          {stepFormSelected === 'description-audio' && !isSuccessMessage
            ? (
              <>
                <div className="label__container">
                  <p className="form__label">
                    Descrição por áudio *
                  </p>
                </div>

                {audioUri === '' && !isRecording
                  ? (
                    <button
                      className="form__button"
                      type="button"
                      onClick={() => {
                        onStartRecord();
                      }}
                    >
                      Gravar
                    </button>
                  ) : null}

                {isRecording
                  ? (
                    <button
                      className="form__button"
                      type="button"
                      onClick={() => {
                        onPauseRecord();
                      }}
                    >
                      Parar
                    </button>
                  ) : null}

                {audioUri && isRecording === false
                  ? (
                    <button
                      className="form__button"
                      type="button"
                      onClick={(event) => {
                        setMessageWarning('');
                        sendSolicitationCreate(event);
                      }}
                    >
                      Enviar
                    </button>
                  ) : null}

                <button
                  className="form__button--outline"
                  type="button"
                  onClick={() => setStepFormSelected('options-description')}
                >
                  Voltar
                </button>
              </>
            ) : null }

          {stepFormSelected === 'description-text' && !isSuccessMessage
            ? (
              <>
                <div className="label__container">
                  <p className="form__label">
                    Descrição por texto *
                    {` (${description.length}/280)`}
                  </p>
                </div>
                <textarea
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui sua descrição"
                  minLength={10}
                  maxLength={280}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />

                <button
                  className="form__button"
                  type="button"
                  onClick={(event) => {
                    if (!description) {
                      setMessageWarning('Preencha o campo descrição');
                      setIsSuccessMessage(false);
                      setIsLoad(false);
                    } else if (description.length < 10) {
                      setMessageWarning('Campo descrição deve conter no mínimo 10 caracteres');
                      setIsSuccessMessage(false);
                      setIsLoad(false);
                    } else {
                      setMessageWarning('');
                      sendSolicitationCreate(event);
                    }
                  }}
                >
                  Enviar
                </button>
                <button
                  className="form__button--outline"
                  type="button"
                  onClick={() => setStepFormSelected('options-description')}
                >
                  Voltar
                </button>
              </>
            ) : null }

          {isSuccessMessage && messageWarning
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => navigate('/solicitations', { replace: true })}
              >
                Ir para solicitações
              </button>
            ) : null }
        </div>
      </div>
    </>
  );
}
