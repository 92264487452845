/* eslint-disable no-console */
/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

import NavBar from '../../components/NavBar';
import Load from '../../components/Load';
import { getToken } from '../../api/auth';
import removeLetter from '../../utils/masks/removeLetter';
import removeSpecial from '../../utils/masks/removeSpecial';
import { addMoneyMaskInput, removeMoneyMask } from '../../utils/masks/manipulateMoneyMask';

import { FinancialsCreateFetch } from '../../api/services/FinancialAPI';

export default function FinancialCreate({ history }) {
  const token = getToken();

  const [loanValue, setLoanValue] = useState('');
  const [messageWarning, setMessageWarning] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const navigate = useNavigate();

  async function sendLoanCreate(event) {
    event.preventDefault();
    setIsLoad(true);
    const valueLoanWithoutMask = removeMoneyMask(loanValue);

    if (valueLoanWithoutMask < 1) {
      setMessageWarning('Preencha o campo valor da sua antecipação');
      setIsSuccessMessage(false);
      setIsLoad(false);
    } else {
      try {
        const resultLoanCreate = await FinancialsCreateFetch(
          token,
          valueLoanWithoutMask,
        );
        console.log('sendLoanCreate | resultLoanCreate', resultLoanCreate);
        setIsLoad(false);
        setIsSuccessMessage(resultLoanCreate.isSuccess);
        setMessageWarning(resultLoanCreate.message);
      } catch (error) {
        console.log('sendLoanCreate | error', error);
        setIsSuccessMessage(false);
        setMessageWarning('No momento esse recurso está indisponível, tente novamente mais tarde.');
        setIsLoad(false);
      }
    }
  }

  function onChangeMaskMoney(text) {
    const loanValueWithoutLetter = removeLetter(text);
    console.log('onChangeMaskMoney | loanValueWithoutLetter', loanValueWithoutLetter);
    const loanValueWithoutSpecial = removeSpecial(loanValueWithoutLetter);
    console.log('onChangeMaskMoney | loanValueWithoutSpecial', loanValueWithoutSpecial);
    const textToInt = parseInt(loanValueWithoutSpecial);
    console.log('onChangeMaskMoney | textToInt', textToInt);
    const intToText = textToInt.toString();
    console.log('onChangeMaskMoney | intToText', intToText);
    const textWithMaskMoney = addMoneyMaskInput(intToText);
    console.log('onChangeMaskMoney | loanValueWithoutSpecial', loanValueWithoutSpecial);

    setLoanValue(textWithMaskMoney);
  }

  return (
    <>
      <Load isShow={isLoad} />
      <NavBar navigation={history} />
      <div className="container__above_header">
        <div className="form">
          <p className="form__description">
            Registrar Antecipação
          </p>

          {messageWarning && <p className={isSuccessMessage ? 'form__message form__message--success' : 'form__message form__message--error'}>{messageWarning}</p>}

          {!isSuccessMessage
            ? (
              <>
                <div className="label__container">
                  <p className="form__label">
                    Valor da Antecipação *
                  </p>
                </div>
                <input
                  className="form__input"
                  type="text"
                  placeholder="Digite aqui o Valor da sua Antecipação"
                  value={loanValue}
                  maxLength={13}
                  onChange={(event) => onChangeMaskMoney(event.target.value)}
                />

                <button
                  className="form__button"
                  type="button"
                  onClick={(event) => sendLoanCreate(event)}
                >
                  Registrar
                </button>
              </>
            ) : null }

          {isSuccessMessage && messageWarning
            ? (
              <button
                className="form__button"
                type="button"
                onClick={() => navigate('/financial', { replace: true })}
              >
                Ir para antecipações
              </button>
            ) : null }

        </div>
      </div>
    </>
  );
}
