/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import './styles.css';

import calendarWithHourAndMinutesMask from '../../../../utils/masks/calendarWithHourAndMinutesMask';

export default function NewsDetailCard({ newsParam }) {
  return (
    <div
      className="details--container"
      aria-hidden="true"
    >
      <p className="details--text details--text-title">
        TÍTULO
      </p>
      <p className="details--text details--text-description">
        {(newsParam.title)}
      </p>
      <p className="details--text details--text-title">
        DATA DE CRIAÇÃO
      </p>
      <p className="details--text details--text-description">
        {(calendarWithHourAndMinutesMask(newsParam.created))}
      </p>
      <p className="details--text details--text-title">
        DESCRIÇÃO
      </p>
      <p className="details--text details--text-description">
        {(newsParam.description)}
      </p>
    </div>
  );
}
